import Axios from 'axios';
import hu from 'date-fns/locale/hu';
import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Card, Col, Row, CustomInput } from 'reactstrap';
import { FilePickerInput } from '../components/FilePickerInput';
import { IFile } from '../redux/products/ProductTypes';

registerLocale('hu', hu);

const initDate = {
  from: new Date(),
  to: new Date(),
};

const EmailsScreen = () => {
  const [file1, setFile1] = useState<File>();
  const [file2, setFile2] = useState<File>();

  const [selectedFile1, setSelectedFile1] = useState<IFile>();
  const [selectedFile2, setSelectedFile2] = useState<IFile>();

  const [isOn1, setIsOn1] = useState(false);
  const [isOn2, setIsOn2] = useState(false);

  const [date, setDate] = useState(initDate);

  const uploadPicture1 = () => {};
  const uploadPicture2 = () => {};
  return (
    <>
      <h1>Visszaigazoló email szerkesztése</h1>
      <Row>
        <Col>
          <legend>
            <b>Első email</b>
          </legend>
          <Card style={{ padding: '1rem' }}>
            <legend>Mettől meddig küldje a rendszer?</legend>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around',
                marginBottom: '2rem',
              }}
            >
              <DatePicker
                locale='hu'
                selected={date.from}
                onChange={(e) => e}
                maxDate={new Date()}
                dateFormat='yyyy. MMMM d.'
              />
              <DatePicker
                locale='hu'
                selected={date.to}
                onChange={(e) => setDate({ ...date, to: e! })}
                maxDate={new Date()}
                dateFormat='yyyy. MMMM d.'
              />
            </div>

            <Col>
              <FilePickerInput
                onFileSelect={(file) => setFile1(file)}
                defaultPicture={selectedFile1}
                onUploadClicked={() => uploadPicture1()}
              />
            </Col>
          </Card>
        </Col>
        <Col>
          <legend>
            <b>Második email</b>
          </legend>
          <Card style={{ padding: '1rem' }}>
            <legend>Mettől meddig küldje a rendszer?</legend>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around',
                marginBottom: '2rem',
              }}
            >
              <DatePicker
                locale='hu'
                selected={date.from}
                onChange={(e) => e}
                maxDate={new Date()}
                dateFormat='yyyy. MMMM d.'
              />
              <DatePicker
                locale='hu'
                selected={date.to}
                onChange={(e) => setDate({ ...date, to: e! })}
                maxDate={new Date()}
                dateFormat='yyyy. MMMM d.'
              />
            </div>

            <Col>
              <FilePickerInput
                onFileSelect={(file) => setFile2(file)}
                defaultPicture={selectedFile2}
                onUploadClicked={() => uploadPicture2()}
              />
            </Col>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EmailsScreen;
