import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button, ButtonGroup, Col, Row } from 'reactstrap';
import OrderItemView from '../../components/orders/OrderItemView';
import { OrderActions } from '../../redux/orders/OrderActions';
import { IOrder } from '../../redux/orders/OrderTypes';
import { RootState } from '../../redux/store';

const OrdersScreen = (props: Props) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [selectedCityIds, setSelectedCityIds] = useState<number[]>([1, 2]);
  const { fetchOrders } = props.orderActions;

  useEffect(() => {
    fetchOrders(0, 0);
  }, [fetchOrders]);

  function handleCityClick(cityId: number) {
    const index = selectedCityIds.indexOf(cityId);
    if (index !== -1) {
      selectedCityIds.splice(index, 1);
    } else {
      selectedCityIds.push(cityId);
    }
    setSelectedCityIds(selectedCityIds);
    forceUpdate();
  }

  const cityFilter = (value: IOrder) => selectedCityIds.includes(value.cityId);

  return (
    <>
      <h1>Beérkezett rendelések</h1>
      <Row className='d-flex align-items-center'>
        <Col lg='auto'>
          <ButtonGroup>
            <Button color='primary' onClick={() => handleCityClick(2)}>
              KECSKEMET
            </Button>
            <Button color='primary' onClick={() => handleCityClick(1)}>
              SZOLNOK
            </Button>
          </ButtonGroup>
        </Col>
        <Col>
          <h4>
            {' '}
            <b>Megjelenítve:</b>
          </h4>
          {selectedCityIds.map((n) => (n === 1 ? ' Szolnok ' : ' Kecskemét '))}
        </Col>
      </Row>
      <h3>Új rendelések</h3>
      <Row>
        {props.orders
          .filter(cityFilter)
          .filter((o) => o.phase === 'NEW_ORDER')
          .map((order) => (
            <Col key={order.id} lg='auto'>
              <OrderItemView
                orderActions={props.orderActions}
                key={order.id}
                order={order}
              />
            </Col>
          ))}
      </Row>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    orders: state.orderReducer.orders,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    orderActions: OrderActions(dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;
export default connector(OrdersScreen);
