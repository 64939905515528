import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Row,
} from 'reactstrap';
import { CouponActions } from '../../redux/coupons/CouponActions';
import { CouponActionType, ICoupon } from '../../redux/coupons/CouponTypes';
import { RootState } from '../../redux/store';

type Props = {
  coupon: ICoupon;
  couponActions?: CouponActionType;
};
const CouponItemView = (props: Props) => {
  const { coupon } = props;
  const history = useHistory();
  const { path } = useRouteMatch();

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore((o) => !o);
  };

  // const toppings = product.toppings!.map(id => props.allToppings?.find(t => t.id === id))
  function deleteCouponClicked() {
    props.couponActions?.deleteCoupon(coupon);
    history.push(`${path}/`);
    alert('Kupon kitörölve!');
  }

  function updateCouponClicked() {
    history.push(`${path}/updatecoupon/${props.coupon.id}/false`);
  }

  function copyCouponClicked() {
    history.push(`${path}/copycoupon/${props.coupon.id}/true`);
  }

  return (
    <Card
      style={{ minHeight: '400px', overflowY: 'hidden', position: 'relative' }}
    >
      <CardHeader>
        <Row>
          {' '}
          <Col>
            <h5>Azonosító: {coupon.id}</h5>{' '}
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>{coupon.name ?? '-'}</h5>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <CardText>
          <b>Kód: </b>
          {coupon.codeToUse}
        </CardText>
        <CardText>
          <b>Tipus: </b>
          {coupon.amountType === 'FIX' ? 'FIX' : 'Százalékos'}
        </CardText>
        <CardText>
          <b>Mérték: </b>
          {coupon.amount}
        </CardText>
        <CardText>
          <b>Leírás: </b>
          {coupon.description ?? '-'}
        </CardText>
        <CardText>
          <b>Elérhető-e a kínálatban: </b>
          {coupon.isAvailable ? 'Igen' : 'Nem'}
        </CardText>
        <Row>
          <Col>
            <Button color='warning' onClick={updateCouponClicked}>
              Módosítás
            </Button>
          </Col>
          <Col>
            <Button color='info' onClick={copyCouponClicked}>
              Másolás
            </Button>
          </Col>
          <Col>
            <Button color='danger' onClick={deleteCouponClicked}>
              Törlés
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    coupons: state.couponReducer.coupons,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    couponActions: CouponActions(dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(CouponItemView);
