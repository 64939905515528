import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { NavLink } from 'react-router-dom';

export const NavMenu = (props: any) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button onClick={toggle}>Menü</Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Navigáció</ModalHeader>
        <ModalBody>
          <ul>
            {props?.routes?.map((prop: any, key: any) => {
              return (
                <li key={key}>
                  <NavLink
                    to={prop.path}
                    className='nav-link'
                    activeClassName='active'
                  >
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </ModalBody>
      </Modal>
    </div>
  );
};
