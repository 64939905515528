import {IOrder, OrderActionType, OrderDispatchType} from "./OrderTypes";
import Axios from "axios";
import {socket} from "../../configureSocketIo";
import moment from "moment";

export const OrderActions = (dispatch: (action: OrderDispatchType) => {}): OrderActionType => {
    return {
        fetchOrders: async (from: number, to: number) => {
            return Axios.get('/orders', {
                params: {
                    from: moment().startOf('week').toISOString(),
                    to: moment().endOf('day').toISOString(),
                    paymentStatus: "DONE"
                }
            }).then(res => {
                //No point to fetch 50000 order every time since we only need new orders, now it only fetches the last 50 order
                dispatch({type: "ORDERS_DOWNLOADED", payloadOrders: res.data.slice(Math.max(res.data.length - 50,0))})
            })
        },
        fetchArchives: async (from: Date, to: Date,query?:any) => {
            return Axios.get('/orders', {
                params: {
                    from: moment(from).startOf('day').toISOString(),
                    to: moment(to).endOf('day').toISOString(),
                    order: ['createdAt','DESC'],
                    ...query
                }
            }).then(res => {
                dispatch({type: "ARCHIVE_DOWNLOADED", payloadOrders: res.data})
            })
        },
        emitOrderStartedPreparing: async (orderId: number, shippingTime: number) => {
            dispatch({type:"ORDER_STARTED_PREPARING", payloadOrderId:orderId, payloadOrderShippingTime: shippingTime })
            socket.emit("ORDER_STARTED_PREPARING", { orderId, shippingTime })
            console.log("Order started preparing emitted")
        },
        emitOrderReadyToShip: (orderId: number) => {
            socket.emit("ORDER_READY_TO_SHIP", orderId)
        },
        emitOrderDone: (orderId: number) => {
            socket.emit("ORDER_DONE", orderId)
        },
        onOrderStartedPreparing: (orderId: number) => {
            dispatch({type: "ORDER_STARTED_PREPARING", payloadOrderId: orderId})
        },
        onOrderCreated: (order: IOrder) => {
            dispatch({type: "ORDER_CREATED", payloadOrder: order})
        },
        onOrderReadyToShip: (orderId: number) => {
            dispatch({type: "ORDER_READY_TO_SHIP", payloadOrderId: orderId})
        },
        onOrderDone: (orderId: number) => {
            dispatch({type: "ORDER_DONE", payloadOrderId: orderId})
        }
    }
}
