import { ProductDispatchType, ProductState, ICategory } from "./ProductTypes";


export function findInTreeView(childCategories: ICategory[] | undefined, idToFind: number): ICategory | undefined {
        if (!childCategories || childCategories.length === 0) return undefined
    const found = childCategories.find(c => c.id === idToFind)
    if (found) return found
    else return findInTreeView(childCategories?.flatMap(c => c.childCategories ?? []), idToFind)
}


const initState: ProductState = {
    products: [],
    categories: []
}

const ProductReducer = (state = initState, action: ProductDispatchType): ProductState => {
    switch (action.type) {
        case "PRODUCTS_DOWNLOADED":
            return {
                ...state,
                products: action.payloadProducts ?? [],
            }
        case "PRODUCT_ADDED":
            findInTreeView(state.categories, action.payloadProduct!.parentCategoryId!)?.items.push(action.payloadProduct!)
            return {
                ...state,
                products: state.products.concat(action.payloadProduct ?? [])
            }
        case "PRODUCT_DELETED":
            return {
                ...state,
                products: state.products.filter(p => p.id !== action.payloadProduct?.id)
            }

        case "PRODUCT_MODIFIED":
            const i = state.products.findIndex(p => p.id === action.payloadProduct?.id)
            state.products.splice(i, 1, action.payloadProduct!)
            return state

        case "CATEGORIES_DOWNLOADED": {
            return {
                ...state,
                categories: action.payloadCategories ?? []
            }
        }
        case "CATEGORY_ADDED":
            findInTreeView(state.categories, action.payloadCategory!.parentCategoryId!)?.childCategories.push(action.payloadCategory!)
            return state

        case "CATEGORY_MODIFIED":
            const i1 = state.categories.findIndex(p => p.id === action.payloadCategory?.id)
            state.categories.splice(i1, 1, action.payloadCategory!)
            return state

        case "CATEGORY_DELETED":
            const parentCat = findInTreeView( state.categories, action.payloadCategory!.parentCategoryId!)
            const is = parentCat?.childCategories.findIndex(c => c.id === action.payloadCategory?.id)
            if (is) {
                parentCat!.childCategories = [...parentCat!.childCategories.slice(0, is), ...parentCat!.childCategories.slice(is + 1)]
            }
            console.log("is", is);

            return {
                ...state
            }

        default:
            return state
    }
}
export default ProductReducer
