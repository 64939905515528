import React, { useEffect, useState, useRef } from 'react'
import Select, { OptionTypeBase } from 'react-select'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Card } from 'reactstrap'
import { ICategory, IProduct } from '../../redux/products/ProductTypes'
import { CategoryTreeView } from '../category/CategoryTreeView'

export type CommonType = {
    productId?: number
    item?: IProduct
    itemId?: number
    categoryId?: number
    category?: ICategory
}

type Props = {
    defaultProdContent: CommonType[],
    availableCategories: ICategory[],
    availableProducts: IProduct[],
    modal: boolean,
    closeModal: () => void
    onSaveClicked: (array: CommonType[]) => void,
    doNotShowCats: boolean
}

export const ToppingModal = (props: Props) => {
    const { modal, closeModal, onSaveClicked, defaultProdContent, availableCategories, availableProducts } = props
    const [prodContent, setProdContent] = useState<CommonType[]>([])
    const [catContent, setCatContent] = useState<CommonType[]>([])
    useEffect(() => {
        setProdContent(defaultProdContent.filter(c => c.item))
        setCatContent(defaultProdContent.filter(c => c.categoryId))
    }, [modal, defaultProdContent])

    function handleProdChange(products: IProduct[]) {
        setProdContent(products?.map(p => {
            return { itemId: p.id, item: p }
        }))
    }

    function handleCategoryChange(categories: ICategory[]) {
        setCatContent(categories?.map(p => {
            return { categoryId: p.id, category: p }
        }))
    }

    function save() {
        //@ts-ignore
        onSaveClicked(prodContent?.concat(catContent))
    }

    // function getFullProductName(product: OptionTypeBase): string {
    //     let name = '';
    //     product.parentCategory ? name = getFullProductName(product.parentCategory) + '/' + product.name : name = product.name;
    //     return name
    // }
    const getFullProductNameOneLevel = (product: OptionTypeBase) => product.parentCategory?.name + '/' + product?.name;


    const inputSelect = useRef(null);

    const [filterParentCat, setFilterParentCat] = useState<ICategory>()
    return (
        <Modal isOpen={modal} toggle={closeModal}>
            <ModalHeader toggle={closeModal}>Új feltét felvétele</ModalHeader>
            <ModalBody >

                <Card style={{ backgroundColor: "#eee", padding: '1rem' }}>
                    <legend>Feltét, mint termék</legend>

                    <CategoryTreeView
                        categories={availableCategories}
                        onChange={cat => {
                            setFilterParentCat(cat);
                            //@ts-ignore
                            inputSelect!.current!.state.menuIsOpen = true
                        }}
                        mode="radioSelect"
                        showItems
                        defaultSelected={filterParentCat}
                    />
                    <Select isMulti
                        placeholder="Válassz kategóriát a termék kiválasztásához!"
                        ref={inputSelect}
                        value={prodContent?.map(i => availableProducts.find(ap => ap.id === i.item?.id))}
                        closeMenuOnSelect={false}
                        options={availableProducts.filter(p => p.parentCategoryId === filterParentCat?.id)}
                        getOptionValue={i => i.id!.toString()}
                        getOptionLabel={i => getFullProductNameOneLevel(i)}
                        onChange={e => handleProdChange((e ?? []) as IProduct[])}
                    />
                </Card>
                {!props.doNotShowCats && <Card style={{ backgroundColor: "#eee", padding: '1rem', marginTop: '3rem' }}>
                    <legend >Feltét, mint kategória</legend>
                    <CategoryTreeView
                        categories={availableCategories}
                        onChange={(_, cats) => handleCategoryChange(cats)}
                        mode="multiSelect"
                        defaultSelected={catContent.filter(c => c.category).flatMap(c => c.category!)}
                    />
                </Card>}
            </ModalBody>

            <ModalFooter>
                <Button color="primary" onClick={save}>Felvétel</Button>{' '}
                <Button color="secondary" onClick={closeModal}>Mégsem</Button>
            </ModalFooter>
        </Modal>
    )
}

