import axios from "axios";
import {AuthActionType, AuthDispatchType, FormData} from "./AuthTypes";

export const AuthActions = (dispatch: (action: AuthDispatchType) => void): AuthActionType => {
    return {
        signIn: async (formData: FormData) => {
            return axios.post('/auth/login', JSON.stringify({email: formData.email, password: formData.password}))
                .then(resp => {
                    const {token, refreshToken, user} = resp.data
                    if (formData.isStayLoggedIn) {
                        localStorage.setItem('user', JSON.stringify(user));
                        localStorage.setItem('token', token);
                        localStorage.setItem('refreshToken', refreshToken);
                    }
                    sessionStorage.setItem('user', JSON.stringify(user));
                    sessionStorage.setItem('token', token);
                    sessionStorage.setItem('refreshToken', refreshToken);

                    dispatch({
                        type: "LOGIN_SUCCESS",
                        payloadToken: token,
                        payloadRefreshToken: refreshToken,
                        payloadUserSession: user
                    })
                })
                .catch(() => {
                    dispatch({type: "LOGIN_FAILURE"})
                    throw Error("Login failure")
                })
        },

        signOut: async () => {
            localStorage.clear();
            sessionStorage.clear();
            dispatch({type: "LOGOUT"});
        },
        sessionMaybeAvailable: async () => {
            console.log("Session maybe available run now!");

            if (localStorage.getItem("refreshToken") && localStorage.getItem("user") && localStorage.getItem("token")) {
                const refreshToken = localStorage.getItem("refreshToken")!
                const token = localStorage.getItem("token")!
                const user = JSON.parse(localStorage.getItem("user")!)
                sessionStorage.setItem('user', JSON.stringify(user));
                sessionStorage.setItem('token', token);
                sessionStorage.setItem('refreshToken', refreshToken);
                return axios
                    .post('/auth/verifyToken',{token:token})
                    .then(() => {
                        dispatch({
                            type: "LOGIN_SUCCESS",
                            payloadToken: token,
                            payloadRefreshToken: refreshToken,
                            payloadUserSession: user
                        })
                    })
            }
        }
    }
}

