import React from "react";
import { connect } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Row,
} from "reactstrap";
import { RootState } from "../../redux/store";
import { IUser, WorkerActionType } from "../../redux/workers/WorkerTypes";
import { WorkerActions } from "../../redux/workers/WorkerActions";

type Props = {
  customer: IUser;
  workerActions?: WorkerActionType;
  onDeleteCustomer: (user: IUser) => void;
};
const CustomerItemView = (props: Props) => {
  const { customer } = props;
  const history = useHistory();
  const { path } = useRouteMatch();

  function updateCustomerClicked() {
    history.push(`${path}/updatecustomer/${customer.id}`);
  }
  const [addr] = customer.addresses || [];

  return (
    <Card>
      <CardHeader>
        <Col>
          <h4>Név: {customer.name}</h4>
        </Col>
        <Col>
          <h4>Email: {customer.email}</h4>
        </Col>
      </CardHeader>
      <CardBody>
        <CardText>
          <b>Telefonszám:</b> {customer.phone ?? "-"}
        </CardText>
        {addr && (
          <CardText>
            <b>Címe:</b> {addr.zip} {addr.city} - {addr.street} {addr.number}{" "}
            {addr.floorDoor} {addr.bellComment} {`(${addr.name})`}
          </CardText>
        )}
        {customer.birthday && (
          <CardText>
            <b>Születésnap (hó/nap):</b> {customer.birthday}
          </CardText>
        )}
        {customer.allergies?.length && (
          <>
            <CardText>
              <b>Allergének</b>
            </CardText>
            <ul>
              {customer.allergies.map((a) => (
                <li key={a.id}>{a.name} </li>
              ))}
            </ul>
          </>
        )}
        <Row>
          <Col>
            <Button color="info" onClick={updateCustomerClicked}>
              Módosítás
            </Button>
          </Col>
          <Col>
            <Button
              color="danger"
              onClick={() => props.onDeleteCustomer(customer)}
            >
              Törlés
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    customers: state.userReducer.workers.filter((w) => w.type === "USER"),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    customerActions: WorkerActions(dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(CustomerItemView);
