import React from 'react';
import { IUser, WorkerStat } from '../../redux/workers/WorkerTypes';
import { Card, CardText } from 'reactstrap';

type Props = {
  workers: WorkerStat[];
};

export const WorkerOrderPreparationStaticstic = (props: Props) => {
  return (
    <>
      <h5>TOP 10 Dolgozó</h5>
      {props.workers.map((u, i) => (
        <Card key={i} style={{ padding: '0.5rem' }}>
          <CardText>
            <span>
              <strong>{i + 1}.</strong>{' '}
            </span>
            <span>
              {u.name ?? 'Nincs név megadva...'} (ID: {u.id})
            </span>
          </CardText>
          <CardText>
            <b>Összes elkészített rendelés:</b> {u.orders} db
          </CardText>
        </Card>
      ))}
    </>
  );
};
