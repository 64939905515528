import Axios from 'axios';
import React, { FormEvent, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { RootState } from '../redux/store';

const defaultCosts = {
  szolnok: 0,
  szolnokItem: 0,
  szolnokHigher: 300,
  szolnokHigherItem: 300,
  szolnokHigher2: 300,
  szolnokHigherItem2: 300,
  kecskemet: 0,
  kecskemetItem: 0,
  kecskemetHigher: 300,
  kecskemetHigherItem: 300,
  kecskemetHigher2: 300,
  kecskemetHigherItem2: 300,
  lactoseFree28: 0,
  lactoseFree52: 0,
  fitness: 0,
};

const PromotionScreen = (props: Props) => {
  const [costs, setCosts] = useState(defaultCosts);
  const [success, setSuccess] = useState(false);
  const [downloaded, setDownloaded] = useState<boolean>(false);
  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    Axios.get('/admin/promotion', { cancelToken: source.token })
      .then((c) => setCosts(c.data))
      .then(() => setDownloaded(true));
    return () => {
      source.cancel();
    };
  }, []);

  function saveCosts(e: FormEvent) {
    e.preventDefault();
    Axios.post('/admin/promotion', costs).then(() => setSuccess(true));
  }

  return (
    <div>
      {downloaded ? (
        <Container>
          <Form>
            <h1>Promóciós árak</h1>
            <FormGroup row>
              <Label sm={2}>Szolnok első promóció</Label>
              <Col sm={6}>
                <Input
                  type='number'
                  value={costs.szolnok}
                  onChange={(e) =>
                    setCosts({ ...costs, szolnok: parseInt(e.target.value) })
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={2}>Termékazonosító</Label>
              <Col sm={6}>
                <Input
                  type='number'
                  value={costs.szolnokItem}
                  onChange={(e) =>
                    setCosts({
                      ...costs,
                      szolnokItem: parseInt(e.target.value),
                    })
                  }
                />
              </Col>
            </FormGroup>
            <div
              style={{
                height: '1px',
                marginBottom: '14px',
                width: '100%',
                backgroundColor: 'grey',
              }}
            ></div>
            <FormGroup row>
              <Label sm={2}>Szolnok második promóció</Label>
              <Col sm={6}>
                <Input
                  type='number'
                  value={costs.szolnokHigher}
                  onChange={(e) =>
                    setCosts({
                      ...costs,
                      szolnokHigher: parseInt(e.target.value),
                    })
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={2}>Termékazonosító</Label>
              <Col sm={6}>
                <Input
                  type='number'
                  value={costs.szolnokHigherItem}
                  onChange={(e) =>
                    setCosts({
                      ...costs,
                      szolnokHigherItem: parseInt(e.target.value),
                    })
                  }
                />
              </Col>
            </FormGroup>
            <div
              style={{
                height: '1px',
                marginBottom: '14px',
                width: '100%',
                backgroundColor: 'grey',
              }}
            ></div>
            <FormGroup row>
              <Label sm={2}>Szolnok harmadik promóció</Label>
              <Col sm={6}>
                <Input
                  type='number'
                  value={costs.szolnokHigher2}
                  onChange={(e) =>
                    setCosts({
                      ...costs,
                      szolnokHigher2: parseInt(e.target.value),
                    })
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={2}>Termékazonosító</Label>
              <Col sm={6}>
                <Input
                  type='number'
                  value={costs.szolnokHigherItem2}
                  onChange={(e) =>
                    setCosts({
                      ...costs,
                      szolnokHigherItem2: parseInt(e.target.value),
                    })
                  }
                />
              </Col>
            </FormGroup>
            <div
              style={{
                height: '1px',
                marginBottom: '14px',
                width: '100%',
                backgroundColor: 'grey',
              }}
            ></div>
            <FormGroup row>
              <Label sm={2}>Kecskemét első promóció</Label>
              <Col sm={6}>
                <Input
                  type='number'
                  value={costs.kecskemet}
                  onChange={(e) =>
                    setCosts({ ...costs, kecskemet: parseInt(e.target.value) })
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={2}>Termékazonosító</Label>
              <Col sm={6}>
                <Input
                  type='number'
                  value={costs.kecskemetItem}
                  onChange={(e) =>
                    setCosts({
                      ...costs,
                      kecskemetItem: parseInt(e.target.value),
                    })
                  }
                />
              </Col>
            </FormGroup>
            <div
              style={{
                height: '1px',
                marginBottom: '14px',
                width: '100%',
                backgroundColor: 'grey',
              }}
            ></div>
            <FormGroup row>
              <Label sm={2}>Kecskemét második promóció</Label>
              <Col sm={6}>
                <Input
                  type='number'
                  value={costs.kecskemetHigher}
                  onChange={(e) =>
                    setCosts({
                      ...costs,
                      kecskemetHigher: parseInt(e.target.value),
                    })
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={2}>Termékazonosító</Label>
              <Col sm={6}>
                <Input
                  type='number'
                  value={costs.kecskemetHigherItem}
                  onChange={(e) =>
                    setCosts({
                      ...costs,
                      kecskemetHigherItem: parseInt(e.target.value),
                    })
                  }
                />
              </Col>
            </FormGroup>
            <div
              style={{
                height: '1px',
                marginBottom: '14px',
                width: '100%',
                backgroundColor: 'grey',
              }}
            ></div>
            <FormGroup row>
              <Label sm={2}>Kecskemét harmadik promóció</Label>
              <Col sm={6}>
                <Input
                  type='number'
                  value={costs.kecskemetHigher2}
                  onChange={(e) =>
                    setCosts({
                      ...costs,
                      kecskemetHigher2: parseInt(e.target.value),
                    })
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={2}>Termékazonosító</Label>
              <Col sm={6}>
                <Input
                  type='number'
                  value={costs.kecskemetHigherItem2}
                  onChange={(e) =>
                    setCosts({
                      ...costs,
                      kecskemetHigherItem2: parseInt(e.target.value),
                    })
                  }
                />
              </Col>
            </FormGroup>

            <h1>Extra árak</h1>
            <FormGroup row>
              <Label sm={2}>Laktózmentes 28cm</Label>
              <Col sm={6}>
                <Input
                  type='number'
                  value={costs.lactoseFree28}
                  onChange={(e) =>
                    setCosts({
                      ...costs,
                      lactoseFree28: parseInt(e.target.value),
                    })
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={2}>Laktózmentes 52cm</Label>
              <Col sm={6}>
                <Input
                  type='number'
                  value={costs.lactoseFree52}
                  onChange={(e) =>
                    setCosts({
                      ...costs,
                      lactoseFree52: parseInt(e.target.value),
                    })
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={2}>Fitness</Label>
              <Col sm={6}>
                <Input
                  type='number'
                  value={costs.fitness}
                  onChange={(e) =>
                    setCosts({
                      ...costs,
                      fitness: parseInt(e.target.value),
                    })
                  }
                />
              </Col>
            </FormGroup>
            <Button type='submit' onClick={saveCosts}>
              Mentés
            </Button>
            {success && <Alert color='success'>Sikeresen elmentve!</Alert>}
          </Form>
        </Container>
      ) : (
        <h1>Töltés...</h1>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    products: state.couponReducer.coupons,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;
export default connector(PromotionScreen);
