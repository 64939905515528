import React from 'react';
import { Container, Navbar, NavbarBrand } from 'reactstrap';

const LoggedOutNavbar = () => {
    return (
        <Navbar expand="lg" color="primary">
            <Container>
                <NavbarBrand>Pizza Sprint Admin</NavbarBrand>
            </Container>
        </Navbar>
    );
}
export default LoggedOutNavbar
