import { NewsState, NewsDispatchType } from "./NewsTypes";

const initState: NewsState = {
    news: []
}

const NewsReducer = (state = initState, action: NewsDispatchType): NewsState => {
    switch (action.type) {
        case "NEWS_DOWNLOADED":
            return {
                ...state,
                news: action.payloadNews!,
            };
        default:
            return state
    }
}
export default NewsReducer
