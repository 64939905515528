import io from 'socket.io-client';
import { BASE_SOCKET_IO_URL } from './configFile';
import { IOrder, OrderActionType } from './redux/orders/OrderTypes';

export var socket: SocketIOClient.Socket;

export const configureSocketIo = (OrderActions: OrderActionType) => {
  const audio = new Audio('/notification.mp3');
  socket = io(BASE_SOCKET_IO_URL, {
    query: { token: sessionStorage.getItem('token') },
    autoConnect: true,
  });
  console.log('Socket connected');
  socket.on('', () => {
    console.log('Event income');
  });
  socket.on('ORDER_CREATED', (order: IOrder) => {
    OrderActions.fetchOrders(0, 0);
    audio.play();
    //OrderActions.onOrderCreated(order)
  });
  socket.on('ORDER_STARTED_PREPARING', (id: number) => {
    OrderActions.onOrderStartedPreparing(id);
  });
  socket.on('ORDER_READY_TO_SHIP', (id: number) => {
    OrderActions.onOrderReadyToShip(id);
  });
  socket.on('ORDER_DONE', (id: number) => {
    OrderActions.onOrderDone(id);
  });
  socket.connect();
};
