import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import CategoryItemView from '../../components/category/CategoryItemView';
import { CategoryTreeView } from '../../components/category/CategoryTreeView';
import { ProductActions } from '../../redux/products/ProductActions';
import { ICategory } from '../../redux/products/ProductTypes';
import { RootState } from '../../redux/store';
import { findInTreeView } from '../../redux/products/ProductReducer';

const CategoryScreen = (props: Props) => {
  const { fetchAllCategory, clearCategories, modifyCategory } =
    props.productActions;

  const history = useHistory();
  const { path } = useRouteMatch();
  useEffect(() => {
    fetchAllCategory();

    return () => {
      setCat(undefined);
      clearCategories();
    };
  }, [fetchAllCategory, clearCategories]);

  const onNewCategoryClicked = () =>
    history.push(`${path}/newcategory/${cat?.id ?? -1}`);

  const handleOnDeleteClicked = async (c: ICategory) => {
    await props.productActions.deleteCategory(c);
    alert(`Katgória ${c.name} kitörölve`);
    window.location.reload(false);
  };
  function setCategory(category: ICategory) {
    setCat(category);
    sessionStorage.setItem(
      'PRODUCT_SCREEN_CATEGORY_ID',
      category.id!.toString()
    );
  }

  let loadedCat: ICategory | undefined;
  const idAsString = sessionStorage.getItem('PRODUCT_SCREEN_CATEGORY_ID');
  if (idAsString) {
    const id = parseInt(idAsString);
    loadedCat = findInTreeView(props.categories, id);
  }
  const [cat, setCat] = useState<ICategory | undefined>(loadedCat);
  return (
    <div>
      <h1>Kategóriák</h1>
      <Button color='success' onClick={onNewCategoryClicked}>
        Új kategória hozzáadása
      </Button>

      <CategoryTreeView
        categories={props.categories}
        onChange={setCategory}
        mode='radioSelect'
        defaultSelected={cat}
      />

      <h2>Ehhez a kategóriához tartozó kategóriák</h2>
      <Row>
        {cat &&
          cat.childCategories
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((c) => (
              <Col key={c.id} lg='4'>
                <CategoryItemView
                  modify={modifyCategory}
                  onDeleteClicked={handleOnDeleteClicked}
                  key={c.id}
                  category={c}
                />
              </Col>
            ))}
      </Row>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    categories: state.productReducer.categories,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    productActions: ProductActions(dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;
export default connector(CategoryScreen);
