import React, { useEffect, useState } from 'react';
import 'react-dropdown-tree-select/dist/styles.css';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { CategoryTreeView } from '../../components/category/CategoryTreeView';
import ProductItemView from '../../components/products/ProductItemView';
import { ProductActions } from '../../redux/products/ProductActions';
import { ICategory } from '../../redux/products/ProductTypes';
import { RootState } from '../../redux/store';
import { findInTreeView } from '../../redux/products/ProductReducer';

const ProductsScreen = (props: Props) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { fetchAllCategory, fetchAllProduct } = props.productActions;
  const [isTableView, setIsTableView] = useState<Boolean>(false);

  useEffect(() => {
    const fv = async () => {
      await fetchAllCategory();
      await fetchAllProduct();
    };
    fv();
  }, [fetchAllCategory, fetchAllProduct]);

  function goToNewProduct() {
    history.push(`${path}/newproduct/${cat?.id ?? -1}`);
  }
  function setCategory(category: ICategory) {
    setCat(category);
    sessionStorage.setItem(
      'PRODUCT_SCREEN_CATEGORY_ID',
      category.id!.toString()
    );
  }

  let loadedCat: ICategory | undefined;
  const idAsString = sessionStorage.getItem('PRODUCT_SCREEN_CATEGORY_ID');
  if (idAsString) {
    const id = parseInt(idAsString);
    loadedCat = findInTreeView(props.categories, id);
  }
  const [cat, setCat] = useState<ICategory | undefined>(loadedCat);
  return (
    <>
      <h1>Termékek szerkesztése</h1>
      <Button color='success' onClick={goToNewProduct}>
        Új termék hozzáadása
      </Button>

      <CategoryTreeView
        categories={props.categories}
        mode='radioSelect'
        onChange={setCategory}
        showItems
        defaultSelected={cat}
      />

      {cat?.items ? (
        <>
          <h2>Ehhez a típushoz tartozó termékek</h2>
          <Button onClick={() => setIsTableView(!isTableView)}>
            {!isTableView ? 'Táblázat nézet' : 'Normál nézet'}
          </Button>
          <Row>
            {!isTableView ? (
              cat.items.map((p) => (
                <Col lg='6' key={p.id}>
                  <ProductItemView
                    key={p.id}
                    product={p}
                    isTableView={isTableView}
                  />
                </Col>
              ))
            ) : (
              <Table hover size='sm' striped>
                <thead>
                  <tr>
                    <th>Gyorsmentés</th>
                    <th>Név</th>
                    <th>Ár</th>
                    <th>Áfa</th>
                    <th>Hűségpont%</th>
                    <th>Elérhető?</th>
                    <th>Külön számlázott?</th>
                    <th>Számla ár</th>
                  </tr>
                </thead>
                <tbody>
                  {cat.items.map((p) => (
                    <ProductItemView
                      key={p.id}
                      product={p}
                      isTableView={isTableView}
                    />
                  ))}
                </tbody>
              </Table>
            )}
          </Row>
        </>
      ) : (
        <h2>Ehhez a kategóriához nem tartozik még termék</h2>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    categories: state.productReducer.categories,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    productActions: ProductActions(dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;
export default connector(ProductsScreen);
