import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { FilePickerInput } from '../components/FilePickerInput';
import { IFile } from '../redux/products/ProductTypes';

type IConfig = {
  id: number;
  pictureId: number;
  stringValue: string;
  key: string;
  picture: IFile;
};

const PopupScreen = () => {
  const [exitFile, setExitFile] = useState<File>();
  const [serverExitFile, setServerExitFile] = useState<IFile>();

  const [cartFile, setCartFile] = useState<File>();
  const [serverCartFile, setServerCartFile] = useState<IFile>();

  const [szolnokFile, setSzolnokFile] = useState<File>();
  const [serverSzolnokFile, setServerSzolnokFile] = useState<IFile>();

  const [kecskemetFile, setKecskemetFile] = useState<File>();
  const [serverKecskemetFile, setServerKecskemetFile] = useState<IFile>();

  useEffect(() => {
    const fv = async () => {
      var params = new URLSearchParams();
      params.append('key', 'popupExit');
      params.append('key', 'popupCart');
      params.append('key', 'szolnokFlyer');
      params.append('key', 'kecskemetFlyer');
      const configs: IConfig[] = await Axios.get('/admin/config', {
        params,
      }).then((res) => res.data);
      console.log(configs);
      configs.forEach((c) => {
        if (c.key === 'popupExit') setServerExitFile(c.picture);
        else if (c.key === 'popupCart') setServerCartFile(c.picture);
        else if (c.key === 'szolnokFlyer') setServerSzolnokFile(c.picture);
        else if (c.key === 'kecskemetFlyer') setServerKecskemetFile(c.picture);
      });
    };
    fv();
  }, []);

  async function uploadPopUpExit() {
    if (exitFile) {
      const formData = new FormData();
      formData.append('file', exitFile);
      const respond = await Axios.post('/admin/files', formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      await Axios.post('/admin/config', {
        key: 'popupExit',
        pictureId: respond.data.id,
      });
      return window.location.reload(false);
    } else {
      alert('Nincs fájl kiválasztva!');
    }
  }

  async function uploadPopUpCart() {
    if (cartFile) {
      const formData = new FormData();
      formData.append('file', cartFile);
      const respond = await Axios.post('/admin/files', formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      await Axios.post('/admin/config', {
        key: 'popupCart',
        pictureId: respond.data.id,
      });
      return window.location.reload(false);
    } else {
      alert('Nincs fájl kiválasztva!');
    }
  }
  async function uploadFlyerSzolnok() {
    if (szolnokFile) {
      const formData = new FormData();
      formData.append('file', szolnokFile);
      const respond = await Axios.post('/admin/files', formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      await Axios.post('/admin/config', {
        key: 'szolnokFlyer',
        pictureId: respond.data.id,
      });
      return window.location.reload(false);
    } else {
      alert('Nincs fájl kiválasztva!');
    }
  }

  async function uploadFlyerKecskemet() {
    if (kecskemetFile) {
      const formData = new FormData();
      formData.append('file', kecskemetFile);
      const respond = await Axios.post('/admin/files', formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      await Axios.post('/admin/config', {
        key: 'kecskemetFlyer',
        pictureId: respond.data.id,
      });
      return window.location.reload(false);
    } else {
      alert('Nincs fájl kiválasztva!');
    }
  }
  return (
    <>
      <h1>Popup-ok szerkesztése</h1>
      <Row>
        <Col>
          <Card style={{ padding: '1rem' }}>
            <legend>
              <b>Exit popup </b>
              <i>(oldal elhagyásakor jelenik meg)</i>
            </legend>
            <FilePickerInput
              onFileSelect={(file) => setExitFile(file)}
              defaultPicture={serverExitFile}
              onUploadClicked={uploadPopUpExit}
            />
          </Card>
        </Col>
        <Col>
          <Card style={{ padding: '1rem' }}>
            <legend>
              <b>Pénztár popup</b>
              <i>(a pénztárnál jelenik meg)</i>
            </legend>
            <h5>
              Szerkesztéshez: Termékek szerkesztése - valamelyik város - ajánlat{' '}
            </h5>
          </Card>
        </Col>
      </Row>
      <h1>Szórólapok szerkesztése</h1>
      <Row>
        <Col>
          <Card style={{ padding: '1rem' }}>
            <legend>
              <b>Szolnok (JPG)</b>
            </legend>
            <FilePickerInput
              onFileSelect={(file) => setSzolnokFile(file)}
              defaultPicture={serverSzolnokFile}
              onUploadClicked={uploadFlyerSzolnok}
            />
          </Card>
        </Col>
        <Col>
          <Card style={{ padding: '1rem' }}>
            <legend>
              <b>Kecskemét (JPG)</b>
            </legend>
            <FilePickerInput
              onFileSelect={(file) => setKecskemetFile(file)}
              defaultPicture={serverKecskemetFile}
              onUploadClicked={uploadFlyerKecskemet}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PopupScreen;
