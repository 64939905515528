import Axios from 'axios'
import React, {FormEvent, useEffect, useState} from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {Alert, Button, Col, Container, Form, FormGroup, Input, Label} from 'reactstrap'
import {RootState} from '../redux/store'

const defaultCosts = {
    szolnok: 0,
    kecskemet: 0,
    szolnokToElse: 300,
    kecskemetToElse: 300,
}
const ShippingScreen = (props: Props) => {
    const [costs, setCosts] = useState(defaultCosts)
    const [success, setSuccess] = useState(false)
    const [downloaded, setDownloaded] = useState<boolean>(false)
    useEffect(() => {
        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();
        Axios.get("/admin/shipping_costs", {cancelToken: source.token})
            .then(c => setCosts(c.data))
            .then(() => setDownloaded(true))
        return () => {
            source.cancel()
        }
    }, [])

    function saveCosts(e: FormEvent) {
        e.preventDefault()
        Axios.post("/admin/shipping_costs", costs)
            .then(() => setSuccess(true))
    }

    return (
        <div>
            <h1>Kiszállítási díj állítása <i>(forintban)</i></h1>
            {downloaded ? <Container>
                <Form>
                    <FormGroup row>
                        <Label sm={2}>Szolnok</Label>
                        <Col sm={6}>
                            <Input type="number" value={costs.szolnok}
                                   onChange={e => setCosts({...costs, szolnok: parseInt(e.target.value)})}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={2}>Kecskemét</Label>
                        <Col sm={6}>
                            <Input type="number" value={costs.kecskemet}
                                   onChange={e => setCosts({...costs, kecskemet: parseInt(e.target.value)})}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={2}>Más helyre Szolnokról</Label>
                        <Col sm={6}>
                            <Input type="number" value={costs.szolnokToElse}
                                   onChange={e => setCosts({...costs, szolnokToElse: parseInt(e.target.value)})}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={2}>Más helyre Kecskemétről</Label>
                        <Col sm={6}>
                            <Input type="number" value={costs.kecskemetToElse}
                                   onChange={e => setCosts({...costs, kecskemetToElse: parseInt(e.target.value)})}/>
                        </Col>
                    </FormGroup>
                    <Button type="submit" onClick={saveCosts}>Mentés</Button>
                    {success && <Alert color="success">Sikeresen elmentve!</Alert>}
                </Form>
            </Container> : <h1>Töltés...</h1>}
        </div>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        products: state.couponReducer.coupons
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {}
}
const connector = connect(mapStateToProps, mapDispatchToProps)
type Props = ConnectedProps<typeof connector>
export default connector(ShippingScreen)
