import moment from "moment";
import { IOrder } from "../../redux/orders/OrderTypes";

export const getInitialColor = (
    order: IOrder,
    archive: boolean = false
): string => {
    const diff = moment().diff(order.createdAt, "minute", true);

    if (archive || order.phase !== "NEW_ORDER") {
        return "";
    }

    if (diff >= 1 && diff < 3) {
        return "warning";
    } else if (diff >= 3) {
        return "danger";
    }
    return "";
};

export function convertPaymentToString(pm: string) {

    switch (pm) {
        case "OTPDIRECT":
            return "Szép kártyával fizetve online";
        case "CASH":
            return "Készpénzzel helyszínen";
        case "CARD_LOCATION":
            return "Kártyával helyszínen";
        case "CARD":
            return "Simple pay-el fizetve online";
        default:
            return "Ismeretelen változó:" + pm;
    }

}

export function getPaymentStatus(status: IOrder["paymentStatus"]) {
    if (status === "DONE") return "Teljesült";
    if (status === "PENDING") return "Fizetésre vár";
    if (status === "CANCELLED") return "Elutasítva!";
}