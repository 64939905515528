import React from 'react'
import Axios from 'axios'

type Props = {
    filePath?: string
}
export const ImageHandler = (props: Props) => {
    const fallBackPicture = "https://via.placeholder.com/200/FFFFFF/000000/?text=NO_IMAGE"
    return (
        <img
            src={props.filePath ? Axios.defaults.baseURL + "/files/" + props.filePath : fallBackPicture}
            style={{height: 200, width: 200, objectFit: "contain"}}
            alt=""/>
    )
}
