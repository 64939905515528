import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import CustomerItemView from '../../components/customer/CustomerItemView';
import { RootState } from '../../redux/store';
import { WorkerActions as UserActions } from '../../redux/workers/WorkerActions';
import { Button, Col, Row, CustomInput, Input } from 'reactstrap';
import { IUser } from '../../redux/workers/WorkerTypes';
import Axios from 'axios';
import { Parser } from 'json2csv';
import { saveAs } from 'file-saver';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons';

const CustomersScreen = (props: Props) => {
  const { fetchOnlyUsers } = props.userActions;
  const [searchStr, setSearchStr] = useState('');

  useEffect(() => {
    fetchOnlyUsers();
  }, [fetchOnlyUsers]);

  async function onDeleteCustomer(user: IUser) {
    await props.userActions.deleteUserById(user);
    alert('Felhasználó kitörölve!');
    window.location.reload();
  }

  function createCSV(data: IUser[] | void[]): string | null {
    const fields = [
      'ID',
      'zip',
      'email',
      'facebookId',
      'googleId',
      'name',
      'phone',
      'description',
      'birthday',
      'loyaltyPoint',
      'lastOrderDate',
    ];
    const opts = { fields, delimiter: ';' };

    try {
      const parser = new Parser(opts);
      const csv = parser.parse(data);
      console.log(csv);

      return csv;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  function filterUsers(userdata: IUser[], city: string, zip?: number) {
    let userExportDataFiltered: IUser[] = [];

    userExportDataFiltered = userdata.flatMap((userData: IUser) => {
      if (userData.addresses.length > 0) {
        let i = 0;
        if (city === 'SZOLNOK') {
          while (
            i < userData.addresses.length &&
            !(
              userData.addresses[i].zip === 5000 ||
              userData.addresses[i].zip === 5008
            )
          ) {
            i++;
          }
        } else if (city === 'KECSKEMET') {
          while (
            i < userData.addresses.length &&
            !(userData.addresses[i].zip === 6000)
          ) {
            i++;
          }
        } else if (city === 'OTHER') {
          while (
            i < userData.addresses.length &&
            (userData.addresses[i].zip === 5000 ||
              userData.addresses[i].zip === 5008 ||
              userData.addresses[i].zip === 6000)
          ) {
            i++;
          }
        } else if (city === 'BYZIP') {
          while (
            i < userData.addresses.length &&
            !(userData.addresses[i].zip === zip)
          ) {
            i++;
          }
        }

        if (i < userData.addresses.length) {
          userData.zip = userData.addresses[i].zip;
          return userData;
        } else {
          return [];
        }
      } else {
        return [];
      }
    });
    console.log(userExportDataFiltered);

    return userExportDataFiltered;
  }

  async function createExport(city: string, zip?: number) {
    const userExportData = await Axios.get('/admin/users/export').then(
      (resp) => resp.data as IUser[]
    );

    const userExportDataFiltered = filterUsers(userExportData, city, zip);
    console.log(userExportDataFiltered);

    const csv = createCSV(userExportDataFiltered);

    if (csv) {
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const date =
        now.toISOString().split('T')[0] +
        `-${hours < 10 ? '0' + hours : hours}-${
          minutes < 10 ? '0' + minutes : minutes
        }`;
      saveAs(blob, `user-export-${date}.csv`);
    }
  }

  const [city, setCity] = useState('SZOLNOK');
  const [zip, setZip] = useState<number>();

  return (
    <div>
      <Row>
        <Col>
          <h1>Vásárlók szerkesztése</h1>
        </Col>
        <Col lg={2}>
          <Button onClick={() => createExport(city, zip)}>Export</Button>
        </Col>
      </Row>
      <Row>
        <Col></Col>
        <Col lg={2}>
          <CustomInput
            checked={city === 'SZOLNOK'}
            type='radio'
            value='SZOLNOK'
            id='szolnok'
            name='szolnok'
            label='Szolnok'
            onChange={(e: any) => setCity(e.target.value)}
          />
          <CustomInput
            checked={city === 'KECSKEMET'}
            type='radio'
            value='KECSKEMET'
            id='kecskemet'
            name='kecskemet'
            label='Kecskemét'
            onChange={(e: any) => setCity(e.target.value)}
          />
          <CustomInput
            checked={city === 'OTHER'}
            type='radio'
            value='OTHER'
            id='other'
            name='other'
            label='Egyéb'
            onChange={(e: any) => setCity(e.target.value)}
          />
          <CustomInput
            checked={city === 'BYZIP'}
            type='radio'
            value='BYZIP'
            id='byzip'
            name='byzip'
            label='Ir.szám szerint'
            onChange={(e: any) => setCity(e.target.value)}
          />
          <Input
            type='text'
            disabled={city !== 'BYZIP'}
            placeholder='Adj meg ir.számot...'
            onChange={(e: any) => setZip(parseInt(e.target.value))}
          />
        </Col>
      </Row>

      <Col lg={4} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        Tartalmazott szó: <br></br>(név, email, telefonszám, születésnap)
        <br></br>Az összes listázásához keress rá, hogy "összes".
        <Input
          type='text'
          placeholder='Keresés...'
          value={searchStr}
          onChange={(e) => setSearchStr(e.target.value?.toLowerCase() ?? '')}
        />
      </Col>

      <Row>
        {props.customers
          .filter((c) =>
            searchStr === 'összes'
              ? true
              : c.name?.toLowerCase().includes(searchStr) ||
                c.email?.toLowerCase().includes(searchStr) ||
                c.phone?.includes(searchStr) ||
                c.birthday?.includes(searchStr)
          )
          .slice(0, searchStr.length > 3 ? props.customers.length : 50)
          .map((c) => (
            <Col lg={4} key={`col-${c.id}`}>
              <CustomerItemView
                key={c.id}
                customer={c}
                onDeleteCustomer={onDeleteCustomer}
              />
            </Col>
          ))}
      </Row>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    customers: state.userReducer.users,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    userActions: UserActions(dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;
export default connector(CustomersScreen);
