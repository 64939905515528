import axios from "axios"
import { NewsDispatchType, NewsActionType, INews } from "./NewsTypes"

export const NewsActions = (dispatch: (action: NewsDispatchType) => {}): NewsActionType => {
    return {
        fetchNews: async () => {
            axios.get("/admin/news")
                .then(resp => resp.data as INews[])
                .then(workers => {
                    dispatch({ type: "NEWS_DOWNLOADED", payloadNews: workers })
                })
        },
        addNewNews: async (news: INews,file?:File) => {
            if (file) {
                const formData = new FormData();
                formData.append("file", file)
                return axios.post("/admin/files", formData, {headers: {'content-type': 'multipart/form-data'}})
                    .then(res => res.data.id)
                    .then(id => axios.post("/admin/news", {...news, pictureId: id}))
                    .catch(e => alert("HIBA"))
            }
            return axios.post("/admin/news", news)
                .catch(() => alert("Hiba történt"))

        },
        deleteNews: async (news: INews) => {
            return axios.delete("/admin/news/" + news.id)
        },
        modifyNews: async (news: INews,file?:File) => {
            if (file) {
                const formData = new FormData();
                formData.append("file", file)
                return axios.post("/admin/files", formData, {headers: {'content-type': 'multipart/form-data'}})
                    .then(res => res.data.id)
                    .then(id => axios.put("/admin/news/"+news.id, {...news, pictureId: id}))
            }
            return axios.put("/admin/news/" + news.id, news)
                .catch(e => alert(e))
        }
    }
}
