import React, {useEffect} from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {AuthActions} from '../redux/auth/AuthActions'
import {RootState} from '../redux/store'

const LogoutScreen = (props: Props) => {
    useEffect(() => {
        props.AuthActions.signOut()
    }, [props.isLoggedIn,props.AuthActions])
    return (
        <div>
            Logging you out
        </div>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        isLoggedIn: state.authReducer.isLoggedIn
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        AuthActions: AuthActions(dispatch)
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type Props = ConnectedProps<typeof connector>
export default connector(LogoutScreen)

