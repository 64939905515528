import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import DashboardScreen from './screens/DashboardScreen'
import LoginScreen from './screens/LoginScreen'


const RouterApp = () => {

    return (
        <Switch>
            <Route exact path="/" component={LoginScreen}/>
            <Route path="/login" component={LoginScreen}/>
            <Route path="/dashboard" component={DashboardScreen}/>
            <Redirect to='/login'/>
        </Switch>
    )
}

export default RouterApp