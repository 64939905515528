import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import { IProductAddition } from "../../redux/products/ProductTypes";

type Props = {
  prodContent: IProductAddition;
  index: number;
  onDeleteClicked: () => void;
  onAdditionChange: (index: number, addition: IProductAddition) => void;
};

export const ProductAdditionView = (props: Props) => {
  const { prodContent, index, onDeleteClicked } = props;

  return (
    <div key={prodContent.item?.id}>
      {index !== 0 && (
        <hr style={{ width: "100%", textAlign: "left", marginLeft: 0 }} />
      )}
      <Row className="align-items-center">
        <Col>
          <h5>{prodContent.item?.name ?? prodContent.category?.name}</h5>
          Azon:{prodContent.itemId ?? prodContent.categoryId}
        </Col>
        <Col className="col-lg-auto">
          {/* The onclick is a fuckUp logic, but working */}
          {/* <Row className="align-items-center">
            <Col>
              <Button
                onClick={() =>
                  prodContent.minQuantity > 0 &&
                  props.onAdditionChange(index, {
                    ...prodContent,
                    minQuantity: prodContent.minQuantity - 1,
                  })
                }
                color="info"
              >
                -
              </Button>
            </Col>
            <Col>
              <h6>Min: {prodContent.minQuantity} db</h6>
            </Col>
            <Col>
              <Button
                onClick={() =>
                  prodContent.minQuantity < prodContent.maxQuantity &&
                  prodContent.minQuantity < 9 &&
                  props.onAdditionChange(index, {
                    ...prodContent,
                    minQuantity: prodContent.minQuantity + 1,
                  })
                }
                color="info"
              >
                +
              </Button>
            </Col>
          </Row> */}
          <Row className="align-items-center">
            <Col>
              <Button
                onClick={() =>
                  prodContent.minQuantity < prodContent.maxQuantity &&
                  prodContent.maxQuantity > 0 &&
                  props.onAdditionChange(index, {
                    ...prodContent,
                    maxQuantity: prodContent.maxQuantity - 1,
                  })
                }
                color="info"
              >
                -
              </Button>
            </Col>
            <Col>
              <h6>Max: {prodContent.maxQuantity} db</h6>
            </Col>
            <Col>
              <Button
                onClick={() =>
                  prodContent.maxQuantity < 9 &&
                  props.onAdditionChange(index, {
                    ...prodContent,
                    maxQuantity: prodContent.maxQuantity + 1,
                  })
                }
                color="info"
              >
                +
              </Button>
            </Col>
          </Row>
        </Col>
        <Col>
          <Button onClick={onDeleteClicked} color="danger">
            <span>
              <FontAwesomeIcon size="2x" icon={faTimes} />
            </span>
          </Button>
        </Col>
      </Row>
    </div>
  );
};
