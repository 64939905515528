import moment from 'moment';
import 'moment/locale/hu';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import './assets/css/bootstrap.min.css';
import './assets/perfect-scrollbar.css';
import './assets/scss/paper-dashboard.scss';
import { configFile } from './configFile';
import { configureAxios } from './configureAxios';
import { AuthActions } from './redux/auth/AuthActions';
import reduxStore from './redux/store';
import RouterApp from './RouterApp';

const App = () => {
  moment.locale('hu');

  const {
    store,
    // persistor
  } = reduxStore();
  useEffect(() => {
    configureAxios(configFile, store.dispatch);
    AuthActions(store.dispatch).sessionMaybeAvailable();
  }, [store.dispatch]);

  return (
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <BrowserRouter>
        <RouterApp />
      </BrowserRouter>
      {/* </PersistGate> */}
    </Provider>
  );
};

export default App;
