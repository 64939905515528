import hu from 'date-fns/locale/hu';
import React, { FormEvent, useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import Axios from 'axios';
import {
  Button,
  Col,
  Container,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Row,
  InputGroupAddon,
  InputGroup,
  InputGroupText,
  Card,
} from 'reactstrap';
import { CategoryTreeView } from '../../components/category/CategoryTreeView';
import { FilePickerInput } from '../../components/FilePickerInput';
import { CouponActions } from '../../redux/coupons/CouponActions';
import { ICoupon } from '../../redux/coupons/CouponTypes';
import { ProductActions } from '../../redux/products/ProductActions';
import { ICategory, IProduct } from '../../redux/products/ProductTypes';
import { RootState } from '../../redux/store';
import { IFile } from '../../redux/products/ProductTypes';
registerLocale('hu', hu);

const getInitialCoupon = (): ICoupon => {
  return {
    isAvailable: true,
    name: '',
    amount: 0,
    codeToUse: '',
    amountType: 'FIX',
    isMulti: true,
    validFrom: new Date(),
    validTo: new Date(),
    availableForProductsAndCategories: [],
    displayedOnFrontend: false,
    whichCity: 'SZOLNOK',
    pictureId: null,
  };
};

const NewCouponScreen = (props: Props) => {
  let { id, clone } = useParams();

  let loadedCoupon: ICoupon | undefined;
  if (id) {
    loadedCoupon = props.coupons.find((p) => p.id === parseInt(id!));
  }
  const [coupon, setCoupon] = useState<ICoupon>(
    loadedCoupon ?? getInitialCoupon()
  );
  const [selectedCategories, setSelectedCategories] = useState<ICategory[]>(
    coupon.availableForProductsAndCategories
      .filter((a) => a.category)
      .flatMap((a) => a.category!)
  );
  const [selectedProducts, setSelectedProducts] = useState<IProduct[]>(
    coupon.availableForProductsAndCategories
      .filter((a) => a.product)
      .flatMap((a) => a.product!)
  );

  const history = useHistory();

  useEffect(() => {
    props.productActions.fetchAllCategory();
    props.productActions.fetchAllProduct();

    if (id && clone === 'true' && loadedCoupon) {
      setCoupon({ ...loadedCoupon, id: undefined, name: '', codeToUse: '' });
    }
  }, [props.productActions]);

  async function addNewCoupon(e: FormEvent) {
    e.preventDefault();
    if (selectedCategories.length <= 0 && selectedProducts.length <= 0) {
      alert('Nem választottál se kategórát se terméket a kuponhoz.');
      return;
    }

    // New or Clone coupon
    if (id && clone === 'false') {
      await props.couponActions.modifyCoupon(
        coupon,
        selectedProducts,
        selectedCategories
      );
    } else {
      await props.couponActions.addCoupon(
        coupon,
        selectedProducts,
        selectedCategories
      );
    }
    history.goBack();
  }

  let title = 'Új kupon hozzáadása';

  if (id) {
    title = clone === 'true' ? 'Kupon másolása' : 'Kupon szerkesztése';
  }

  const buttonText = title;

  const [selectedFilterProduct, setSelectedFilterProduct] =
    useState<ICategory>();

  // File input
  const [file, setFile] = useState<File>();
  console.log(props.categories.flat(3));

  const uploadFile = async (file: File | undefined, id: number | undefined) => {
    const formData = new FormData();

    formData.append('file', file!);
    const response = await Axios.post('/admin/files', formData, {
      headers: { 'content-type': 'multipart/form-data' },
      params: { fileName: 'coupon_' + id },
    });

    await props.couponActions.modifyCoupon(
      { ...coupon, pictureId: response.data.id },
      selectedProducts,
      selectedCategories
    );

    return;
  };

  // Get filepath for display
  const [defaultPicture, setDefaultPicture] = useState<IFile>();

  const fetchFileById = async (fileId: number) => {
    try {
      const response = await Axios.get(`/admin/files/${fileId}`);
      const data = await response.data;

      return data;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (loadedCoupon?.pictureId) {
      fetchFileById(loadedCoupon.pictureId).then((picture) => {
        if (picture.constructor === Array && picture?.length > 0) {
          setDefaultPicture(picture[0]);
        }
      });
    }
  }, []);

  return (
    <Container>
      <Form onSubmit={addNewCoupon}>
        <h1>{title}</h1>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <legend>Neve</legend>
              <Input
                type='text'
                value={coupon?.name}
                onChange={(e) => setCoupon({ ...coupon, name: e.target.value })}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <legend>Kuponkód</legend>
              <Input
                type='text'
                value={coupon?.codeToUse}
                onChange={(e) =>
                  setCoupon({ ...coupon, codeToUse: e.target.value })
                }
              />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col md={6}>
            <FormGroup>
              <legend>Típus</legend>
              <Row form lg={6}>
                <Col>
                  <CustomInput
                    checked={coupon.amountType === 'FIX'}
                    type='radio'
                    value='FIX'
                    id='type'
                    name='type'
                    label='Fix'
                    onChange={(e: any) =>
                      setCoupon({
                        ...coupon,
                        amountType: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <CustomInput
                    checked={coupon.amountType === 'PERCENT'}
                    type='radio'
                    value='PERCENT'
                    id='type2'
                    name='type'
                    label='Százalék'
                    onChange={(e: any) =>
                      setCoupon({ ...coupon, amountType: e.target.value })
                    }
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <InputGroup>
                <legend>Kedvezmény mértéke</legend>
                <Input
                  type='number'
                  value={coupon.amount}
                  onChange={(e) =>
                    setCoupon({ ...coupon, amount: parseInt(e.target.value) })
                  }
                />
                <InputGroupAddon addonType='append'>
                  <InputGroupText>
                    {coupon.amountType === 'FIX' ? 'HUF' : '%'}
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <legend>Leírás</legend>
          <Input
            type='textarea'
            value={coupon.description}
            onChange={(e) =>
              setCoupon({ ...coupon, description: e.target.value })
            }
          />
        </FormGroup>

        <Row form>
          <Col md={6}>
            <FormGroup>
              <legend>Látható-e a weboldalon</legend>
              <CustomInput
                checked={coupon.displayedOnFrontend}
                onChange={(e) =>
                  setCoupon({
                    ...coupon,
                    displayedOnFrontend: e.target.checked,
                  })
                }
                type='switch'
                id='Látható'
                label={coupon.displayedOnFrontend ? 'Látható' : 'Nem látható'}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <legend>Melyik aloldalon</legend>
              <Row form lg={3}>
                <Col>
                  <CustomInput
                    checked={coupon.whichCity === 'szolnok'}
                    type='radio'
                    value='szolnok'
                    id='szolnok'
                    name='szolnok'
                    label='Szolnok'
                    onChange={(e: any) =>
                      setCoupon({
                        ...coupon,
                        whichCity: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <CustomInput
                    checked={coupon.whichCity === 'kecskemet'}
                    type='radio'
                    value='kecskemet'
                    id='kecskemet'
                    name='kecskemet'
                    label='Kecskemét'
                    onChange={(e: any) =>
                      setCoupon({ ...coupon, whichCity: e.target.value })
                    }
                  />
                </Col>
                <Col>
                  <CustomInput
                    checked={coupon.whichCity === 'BOTH'}
                    type='radio'
                    value='BOTH'
                    id='both'
                    name='Both'
                    label='Mindkét városban'
                    onChange={(e: any) =>
                      setCoupon({ ...coupon, whichCity: e.target.value })
                    }
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>

        <Col>
          <Card style={{ padding: '1rem' }}>
            <legend>
              <b>Fénykép</b>
            </legend>
            <FilePickerInput
              onFileSelect={(file) => setFile(file)}
              defaultPicture={defaultPicture}
              onUploadClicked={() => uploadFile(file!, loadedCoupon?.id)}
            />
          </Card>
        </Col>

        <FormGroup>
          <legend>Elérhető-e</legend>
          <CustomInput
            checked={coupon.isAvailable}
            onChange={(e) =>
              setCoupon({ ...coupon, isAvailable: e.target.checked })
            }
            type='switch'
            id='Elérhető'
            label='Elérhető-e'
          />
        </FormGroup>
        <FormGroup>
          <legend>Mettől - meddig használható fel</legend>
          <Row form>
            <Col>
              <DatePicker
                locale='hu'
                showTimeSelect
                selected={new Date(coupon.validFrom)}
                onChange={(e) => {
                  setCoupon({ ...coupon, validFrom: e! });
                  if (e! > coupon.validTo)
                    setCoupon({ ...coupon, validTo: e!.toISOString() });
                }}
                minDate={new Date()}
                dateFormat='yyyy. MMMM d. hh:mm'
              />
            </Col>
            <Col>
              <DatePicker
                locale='hu'
                showTimeSelect
                selected={new Date(coupon.validTo)}
                onChange={(e) => {
                  setCoupon({ ...coupon, validTo: e! });
                }}
                minDate={new Date(coupon.validFrom)}
                dateFormat='yyyy. MMMM d. hh:mm'
              />
            </Col>
          </Row>
        </FormGroup>

        <CustomInput
          checked={coupon.isMulti}
          onChange={() => {
            setCoupon({ ...coupon, isMulti: !coupon.isMulti });
          }}
          type='switch'
          id='Modosithato'
          label='Bármennyiszer használható'
        />
        {!coupon.isMulti && (
          <FormGroup>
            <legend>Hányszor lehet felhasználni</legend>
            <Input
              required
              type='number'
              value={coupon.timesCanBeUse}
              onChange={(e) =>
                setCoupon({
                  ...coupon,
                  timesCanBeUse: parseInt(e.target.value),
                })
              }
            />
          </FormGroup>
        )}

        <Row>
          <Col>
            <legend>Milyen kategóriákra érvényes a kupon</legend>

            <CategoryTreeView
              categories={props.categories}
              onChange={(_, cats) => setSelectedCategories(cats)}
              mode='multiSelect'
              defaultSelected={selectedCategories}
            />
          </Col>
          <Col>
            <legend>Milyen termékekre érvényes a kupon</legend>

            <CategoryTreeView
              categories={props.categories}
              onChange={(cat) => setSelectedFilterProduct(cat)}
              mode='radioSelect'
              defaultSelected={selectedCategories}
            />

            <Select
              isMulti
              closeMenuOnSelect={false}
              value={selectedProducts}
              placeholder='Válassz terméket a fentebb kiválasztott kategória közül'
              noOptionsMessage={() =>
                'Ehhez a kategóriához nem tartozik több termék, vagy már kiválasztottad innen az összeset'
              }
              options={props.products.filter(
                (p) => p.parentCategoryId === selectedFilterProduct?.id
              )}
              getOptionValue={(i) => i.id!.toString()}
              getOptionLabel={(i) => i.name}
              onChange={(e) => setSelectedProducts(e as any)}
            />
          </Col>
        </Row>
        <Button color='primary' type='submit'>
          {buttonText}
        </Button>
      </Form>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    coupons: state.couponReducer.coupons,
    products: state.productReducer.products,
    categories: state.productReducer.categories,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    couponActions: CouponActions(dispatch),
    productActions: ProductActions(dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;
export default connector(NewCouponScreen);
