import { OrderDispatchType, OrderState } from './OrderTypes';

const initState: OrderState = {
  orders: [],
  archiveOrders: [],
};

const OrderReducer = (
  state = initState,
  action: OrderDispatchType
): OrderState => {
  switch (action.type) {
    case 'ORDERS_DOWNLOADED':
      return {
        ...state,
        orders: action.payloadOrders ?? [],
      };
    case 'ARCHIVE_DOWNLOADED':
      return {
        ...state,
        archiveOrders: action.payloadOrders ?? [],
      };
    case 'ORDER_CREATED':
      return {
        ...state,
        orders: state.orders?.concat(action.payloadOrder!),
      };
    case 'ORDER_STARTED_PREPARING':
      const osp = state.orders?.find((o) => o.id === action.payloadOrderId);
      // @ts-ignore
      osp!.phase = 'UNDER_PREPARATION';
      const map = state.orders?.filter((o) => o.id !== action.payloadOrderId);
      // @ts-ignore
      map?.push(osp);
      return {
        ...state,
        orders: map,
      };
    case 'ORDER_READY_TO_SHIP':
      let orts = state.orders?.find((o) => o.id === action.payloadOrderId);
      orts!.phase = 'READY_TO_SHIP';
      const map1 = state.orders?.filter((o) => o.id !== action.payloadOrderId);
      map1!.push(orts!);
      return {
        ...state,
        orders: map1,
      };

    case 'ORDER_DONE':
      let od = state.orders?.find((o) => o.id === action.payloadOrderId);
      od!.phase = 'DONE';
      const map2 = state.orders?.filter((o) => o.id !== action.payloadOrderId);
      map2!.push(od!);
      return {
        ...state,
        orders: map2,
      };
    default:
      return state;
  }
};
export default OrderReducer;
