import React from 'react'
import { Button, Card, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { IUserAddress } from '../../redux/workers/WorkerTypes'


type Props = {
    addresses: IUserAddress[],
    onChange: (addresses: IUserAddress[]) => void
}
export const CustomerAddressView = (props: Props) => {

    const handleChange = (e: object, i: number) => {
        const key = Object.keys(e)[0]
        //@ts-ignore
        props.addresses[i][key] = e[key]
        props.onChange(props.addresses)
    }

    const deleteAddress = (i: number) => {
        props.addresses.splice(i, 1)
        props.onChange(props.addresses)
    }

    return (
        < >
            {props.addresses.map((a, i) =>
                <Card key={a.id} style={{ padding: 5 }}>
                    <Row form>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Neve</Label>
                                <Input type="text" value={a.name} onChange={e => handleChange({ name: e.target.value }, i)} required />
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Label>Iránysz.</Label>
                                <Input type="number" max="9999" min="1000" value={a.zip ?? 0} onChange={e => handleChange({ zip: parseInt(e.target.value) }, i)} required />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Város</Label>
                                <Input type="text" value={a.city} onChange={e => handleChange({ city: e.target.value }, i)} required />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Utca</Label>
                                <Input type="text" value={a.street} onChange={e => handleChange({ street: e.target.value }, i)} required />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label>Házszám</Label>
                                <Input type="text" value={a.number} onChange={e => handleChange({ number: parseInt(e.target.value) }, i)} required />
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row form>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Emelet/ajtó</Label>
                                <Input type="text" value={a.floorDoor ?? ""} onChange={e => handleChange({ floorDoor: e.target.value }, i)} />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Megjegyzés</Label>
                                <Input type="text" value={a.bellComment ?? ""} onChange={e => handleChange({ bellComment: e.target.value }, i)} />
                            </FormGroup>
                        </Col>
                        <Col md={2} >
                            <Button color="danger" onClick={() => deleteAddress(i)}>Törlés</Button>
                        </Col>
                    </Row>
                </Card>
            )}
        </>
    )
}