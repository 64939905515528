import { CouponDispatchType, CouponState } from './CouponTypes';

const initState: CouponState = {
  coupons: [],
  weeks: [],
};

const CouponReducer = (
  state = initState,
  action: CouponDispatchType
): CouponState => {
  switch (action.type) {
    case 'COUPONS_DOWNLOADED':
      return {
        ...state,
        coupons: action.payloadCoupons!,
      };
    case 'COUPONS_WEEK_DOWNLOADED':
      return {
        ...state,
        weeks: action.payloadCoupons!,
      };
    case 'COUPON_ADDED':
      return {
        ...state,
        coupons: state.coupons.concat(action.payloadCoupon!),
      };
    case 'COUPON_DELETED':
      return {
        ...state,
        coupons: state.coupons.filter((c) => c.id !== action.payloadCoupon?.id),
      };
    case 'COUPON_MODIFIED': {
      const i = state.coupons.findIndex(
        (p) => p.id === action.payloadCoupon?.id
      );
      state.coupons.splice(i, 1, action.payloadCoupon!);
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};
export default CouponReducer;
