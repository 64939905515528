import {
  ICategory,
  IProduct,
  ProductActionType,
  ProductDispatchType,
} from './ProductTypes';
import Axios from 'axios';
import { uploadFile } from '../FileUploader';

export const ProductActions = (
  dispatch: (action: ProductDispatchType) => {}
): ProductActionType => {
  return {
    fetchAllCategory: async () => {
      return Axios.get('/admin/categories', { params: { isCity: true } }).then(
        (res) =>
          dispatch({
            type: 'CATEGORIES_DOWNLOADED',
            payloadCategories: res?.data,
          })
      );
    },
    addNewCategory: async (category: ICategory, file?: File) => {
      return uploadFile(file)
        .then((id) =>
          Axios.post('/admin/categories', { ...category, pictureId: id })
        )
        .then((res) =>
          dispatch({ type: 'CATEGORY_ADDED', payloadCategory: res.data })
        );
    },
    modifyCategory: async (category: ICategory, file?: File) => {
      return uploadFile(file)
        .then((id) =>
          Axios.put('/admin/categories/' + category.id, {
            ...category,
            pictureId: id,
          })
        )
        .then(() =>
          dispatch({ type: 'CATEGORIES_DOWNLOADED', payloadCategories: [] })
        )
        .then(() => ProductActions(dispatch).fetchAllCategory())
        .catch((e) => alert('HIBA a kategória módosítása során!'));
    },
    deleteCategory: async (category: ICategory) => {
      return Axios.delete('/admin/categories/' + category.id)
        .then(() =>
          dispatch({ type: 'CATEGORIES_DOWNLOADED', payloadCategories: [] })
        )
        .then(() => ProductActions(dispatch).fetchAllCategory());
    },

    fetchProductsByCategoryId: async (id: number) => {
      return id === -1
        ? dispatch({ type: 'PRODUCTS_DOWNLOADED', payloadProducts: [] })
        : Axios.get('/admin/products/', {
            params: { parentCategoryId: id },
          }).then((res) =>
            dispatch({ type: 'PRODUCTS_DOWNLOADED', payloadProducts: res.data })
          );
    },
    fetchAllProduct: async () => {
      return Axios.get('admin/products').then((res) =>
        dispatch({ type: 'PRODUCTS_DOWNLOADED', payloadProducts: res.data })
      );
    },
    addNewProduct: async (product: IProduct, file: File | undefined) => {
      return uploadFile(file)
        .then((id) =>
          Axios.post('/admin/products', { ...product, pictureId: id })
        )
        .then((res) =>
          dispatch({ type: 'PRODUCT_ADDED', payloadProduct: res.data })
        );
    },
    modifyProduct: async (product: IProduct, file?: File) => {
      return uploadFile(file)
        .then((id) =>
          Axios.put('/admin/products/' + product.id, {
            ...product,
            pictureId: id,
          })
        )
        .then(() =>
          dispatch({ type: 'PRODUCTS_DOWNLOADED', payloadProducts: [] })
        )
        .then(() =>
          dispatch({ type: 'CATEGORIES_DOWNLOADED', payloadCategories: [] })
        )
        .then(() => ProductActions(dispatch).fetchAllProduct())
        .then(() => ProductActions(dispatch).fetchAllCategory());
    },
    deleteProduct: async (product: IProduct) => {
      return Axios.delete('/admin/products/' + product.id)
        .then(() =>
          dispatch({ type: 'PRODUCT_DELETED', payloadProduct: product })
        )
        .then(() => ProductActions(dispatch).fetchAllCategory());
    },
    clearCategories: () => {
      console.log('Clearing categories...');
      dispatch({ type: 'CATEGORIES_DOWNLOADED', payloadCategories: [] });
    },
    clearProducts: () => {
      console.log('Clearing products...');
      dispatch({ type: 'PRODUCTS_DOWNLOADED', payloadProducts: [] });
    },
  };
};
