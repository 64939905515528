import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Card, CardBody, CardText, Col, Row } from 'reactstrap';
import { ICategory } from '../../redux/products/ProductTypes';
import { ImageHandler } from '../ImageHandler';

type Props = {
  category: ICategory;
  onDeleteClicked: (c: ICategory) => void;
  modify: any;
};
const CategoryItemView = (props: Props) => {
  const { category } = props;
  const history = useHistory();
  const { path } = useRouteMatch();

  function updateCouponClicked() {
    history.push(`${path}/updatecategory/${category.id}`);
  }

  return (
    <Card>
      <CardBody>
        <Row>
          <Col lg='6'>
            <h6>Azonosító: {category.id ?? '-'}</h6>
            <ImageHandler filePath={category.picture?.filePath} />
          </Col>
          <Col lg='6'>
            <h5>{category.name ?? '-'}</h5>
            <CardText>
              <b>Látható-e a honlapon</b>{' '}
              {!category.isHiddenInFrontend ? 'Igen' : 'Nem'}
            </CardText>
            <Row>
              <Col>
                <Button
                  color='danger'
                  onClick={() => props.onDeleteClicked(category)}
                >
                  Törlés
                </Button>
              </Col>
              <Col>
                <Button color='info' onClick={updateCouponClicked}>
                  Szerkesztés
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default CategoryItemView;
