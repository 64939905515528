import {
  IUser,
  UserStat,
  WorkerActionType,
  WorkerDispatchType,
  WorkerStat,
} from './WorkerTypes';
import axios from 'axios';
import moment from 'moment';

export const WorkerActions = (
  dispatch: (action: WorkerDispatchType) => {}
): WorkerActionType => {
  return {
    fetchOnlyWorkers: async () => {
      axios
        .get('/admin/workers')
        .then((resp) => resp.data as IUser[])
        .then((workers: IUser[]) => {
          dispatch({
            type: 'WORKERS_DOWNLOADED',
            payloadUsers: workers.filter((w) => w.type !== 'USER'),
          });
        });
    },
    fetchOnlyUsers: async () => {
      axios
        .get('/admin/users')
        .then((resp) => resp.data as IUser[])
        .then((users: IUser[]) => {
          dispatch({
            type: 'USERS_DOWNLOADED',
            payloadUsers: users.filter((u) => u.type === 'USER'),
          });
        });
    },
    fetchUserStatistics: async () => {
      axios
        .get('/admin/users/statistics')
        .then((resp) => resp.data as UserStat[])
        .then((stat: UserStat[]) => {
          dispatch({
            type: 'USER_STATISTICS_DOWNLOADED',
            payloadUserStat: stat,
          });
        });
    },
    fetchWorkerStatistics: async () => {
      axios
        .get('/admin/workers/statistics')
        .then((resp) => resp.data as WorkerStat[])
        .then((stat: WorkerStat[]) => {
          dispatch({
            type: 'WORKER_STATISTICS_DOWNLOADED',
            payloadWorkerStat: stat,
          });
        });
    },
    fetchCategoryStatistics: async (from: Date, to: Date) => {
      return axios
        .get('/admin/order-statistics/category', {
          params: {
            from: moment(from).startOf('day').toISOString(),
            to: moment(to).endOf('day').toISOString(),
          },
        })
        .then((res) => {
          dispatch({
            type: 'CATEGORY_STATISTICS_DOWNLOADED',
            payloadCategoryStatistics: res.data,
          });
        });
    },
    fetchPaymentStatistics: async (from: Date, to: Date) => {
      return axios
        .get('/admin/order-statistics/payment', {
          params: {
            from: moment(from).startOf('day').toISOString(),
            to: moment(to).endOf('day').toISOString(),
          },
        })
        .then((res) => {
          dispatch({
            type: 'PAYMENT_STATISTICS_DOWNLOADED',
            payloadPaymentStatistics: res.data,
          });
        });
    },
    addNewUser: async (worker: IUser) => {
      axios
        .post('/admin/workers', worker)
        .then((resp) => window.location.reload())
        .catch(() => alert('Hiba történt'));
    },
    deleteUserById: async (user: IUser) => {
      const resp = await axios.delete('/admin/workers', {
        data: { email: user.email, id: user.id },
      });
      const id = resp.data.id as number;
      dispatch({ type: 'WORKER_DELETED', payloadUserId: id });
    },
    modifyUser: async (worker: IUser) => {
      await axios
        .put('/admin/workers', worker)
        .then((res) =>
          dispatch({ type: 'WORKER_MODIFIED', payloadUser: res.data })
        );
      dispatch({ type: 'WORKER_MODIFIED', payloadUser: worker });
    },
  };
};
