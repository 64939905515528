import {AuthDispatchType, AuthState} from "./AuthTypes";

const initState: AuthState = {
    isLoggedIn: false,
    token: undefined,
    userSession: undefined,
    refeshToken: undefined
}

const authReducer = (state = initState, action: AuthDispatchType): AuthState => {
    switch (action.type) {
        case "LOGIN_SUCCESS":
            return {
                ...state,
                isLoggedIn: true,
                token: action.payloadToken,
                refeshToken: action.payloadRefreshToken,
                userSession: action.payloadUserSession,
            };
        case "LOGIN_FAILURE":
            return {
                isLoggedIn: false,
            };
        case "LOGOUT":
            return {
                isLoggedIn: false,
            };
        case "TOKEN_REFRESHED":
            return {
                ...state,
                token: action.payloadToken
            }
        default:
            return state
    }
}
export default authReducer
