import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, Col, Row } from 'reactstrap';
import { FilePickerInput } from '../components/FilePickerInput';
import { IFile } from '../redux/products/ProductTypes';

type ConfigFile = {
  key: string;
  pictureId?: number;
  picture?: IFile;
  stringValue?: string;
};

const ModifyMainPageMobile = () => {
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);
  const [selectedCity, setSelectedCity] = useState<string>('szolnok');
  //szolnok
  const [file0, setFile0] = useState<File>();
  const [file1, setFile1] = useState<File>();
  const [file2, setFile2] = useState<File>();
  const [file3, setFile3] = useState<File>();
  const [file4, setFile4] = useState<File>();
  const [file5, setFile5] = useState<File>();

  const [mainPagePicture0, setMainPagePicture0] = useState<IFile>();
  const [mainPagePicture1, setMainPagePicture1] = useState<IFile>();
  const [mainPagePicture2, setMainPagePicture2] = useState<IFile>();
  const [mainPagePicture3, setMainPagePicture3] = useState<IFile>();
  const [mainPagePicture4, setMainPagePicture4] = useState<IFile>();
  const [mainPagePicture5, setMainPagePicture5] = useState<IFile>();

  //kecskemet
  const [file6, setFile6] = useState<File>();
  const [file7, setFile7] = useState<File>();
  const [file8, setFile8] = useState<File>();
  const [file9, setFile9] = useState<File>();
  const [file10, setFile10] = useState<File>();
  const [file11, setFile11] = useState<File>();

  const [mainPagePicture6, setMainPagePicture6] = useState<IFile>();
  const [mainPagePicture7, setMainPagePicture7] = useState<IFile>();
  const [mainPagePicture8, setMainPagePicture8] = useState<IFile>();
  const [mainPagePicture9, setMainPagePicture9] = useState<IFile>();
  const [mainPagePicture10, setMainPagePicture10] = useState<IFile>();
  const [mainPagePicture11, setMainPagePicture11] = useState<IFile>();

  useEffect(() => {
    const fv = async () => {
      const configs: ConfigFile[] = (await Axios.get('/admin/config')).data;
      configs.forEach((c) => {
        if (c.key === 'mobile-homebg-szolnok-0') {
          setMainPagePicture0(c.picture);
        } else if (c.key === 'mobile-homebg-szolnok-1') {
          setMainPagePicture1(c.picture);
        } else if (c.key === 'mobile-homebg-szolnok-2') {
          setMainPagePicture2(c.picture);
        } else if (c.key === 'mobile-homebg-szolnok-3') {
          setMainPagePicture3(c.picture);
        } else if (c.key === 'mobile-homebg-szolnok-4') {
          setMainPagePicture4(c.picture);
        } else if (c.key === 'mobile-homebg-szolnok-5') {
          setMainPagePicture5(c.picture);
        } else if (c.key === 'mobile-homebg-kecskemet-6') {
          setMainPagePicture6(c.picture);
        } else if (c.key === 'mobile-homebg-kecskemet-7') {
          setMainPagePicture7(c.picture);
        } else if (c.key === 'mobile-homebg-kecskemet-8') {
          setMainPagePicture8(c.picture);
        } else if (c.key === 'mobile-homebg-kecskemet-9') {
          setMainPagePicture9(c.picture);
        } else if (c.key === 'mobile-homebg-kecskemet-10') {
          setMainPagePicture10(c.picture);
        } else if (c.key === 'mobile-homebg-kecskemet-11') {
          setMainPagePicture11(c.picture);
        }
      });
    };
    fv();
  }, [shouldRefresh, setShouldRefresh]);

  async function uploadPopUp(
    file: File | undefined,
    id: number,
    city?: string
  ) {
    const formData = new FormData();
    formData.append('file', file!);
    const respond = await Axios.post('/admin/files', formData, {
      headers: { 'content-type': 'multipart/form-data' },
      params: { fileName: `mobile-homebg-${city}-${id}` },
    });
    await Axios.post('/admin/config', {
      key: `mobile-homebg-${city}-${id}`,
      pictureId: respond.data.id,
    });
    setShouldRefresh(!shouldRefresh);
  }

  return (
    <>
      <h1>Főoldal szerkesztése (mobil)</h1>
      <Col lg='auto' style={{ marginBottom: '2rem' }}>
        <ButtonGroup>
          <Button
            style={{ marginRight: '2rem' }}
            color='primary'
            onClick={() => setSelectedCity('szolnok')}
          >
            Szolnok
          </Button>
          <Button
            style={{ marginRight: '2rem' }}
            color='primary'
            onClick={() => setSelectedCity('kecskemet')}
          >
            Kecskemét
          </Button>
        </ButtonGroup>
        <h3>
          Kiválasztva: {selectedCity === 'szolnok' ? 'Szolnok' : 'Kecskemét'}
        </h3>
      </Col>
      {selectedCity === 'szolnok' && (
        <>
          <Row>
            <Col>
              <Card style={{ padding: '1rem' }}>
                <legend>
                  <b>Első kép</b>
                </legend>
                <FilePickerInput
                  onFileSelect={(file) => setFile0(file)}
                  defaultPicture={mainPagePicture0}
                  onUploadClicked={() => uploadPopUp(file0!, 0, 'szolnok')}
                />
              </Card>
            </Col>
            <Col>
              <Card style={{ padding: '1rem' }}>
                <legend>
                  <b>Második kép</b>
                </legend>
                <FilePickerInput
                  onFileSelect={(file) => setFile1(file)}
                  defaultPicture={mainPagePicture1}
                  onUploadClicked={() => uploadPopUp(file1!, 1, 'szolnok')}
                />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Card style={{ padding: '1rem' }}>
                <legend>
                  <b>Harmadik kép</b>
                </legend>
                <FilePickerInput
                  onFileSelect={(file) => setFile2(file)}
                  defaultPicture={mainPagePicture2}
                  onUploadClicked={() => uploadPopUp(file2!, 2, 'szolnok')}
                />
              </Card>
            </Col>
            <Col lg={6}>
              <Card style={{ padding: '1rem' }}>
                <legend>
                  <b>Negyedik kép</b>
                </legend>
                <FilePickerInput
                  onFileSelect={(file) => setFile3(file)}
                  defaultPicture={mainPagePicture3}
                  onUploadClicked={() => uploadPopUp(file3!, 3, 'szolnok')}
                />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Card style={{ padding: '1rem' }}>
                <legend>
                  <b>Ötödik kép</b>
                </legend>
                <FilePickerInput
                  onFileSelect={(file) => setFile4(file)}
                  defaultPicture={mainPagePicture4}
                  onUploadClicked={() => uploadPopUp(file4!, 4, 'szolnok')}
                />
              </Card>
            </Col>
            <Col lg={6}>
              <Card style={{ padding: '1rem' }}>
                <legend>
                  <b>Hatodik kép</b>
                </legend>
                <FilePickerInput
                  onFileSelect={(file) => setFile5(file)}
                  defaultPicture={mainPagePicture5}
                  onUploadClicked={() => uploadPopUp(file5!, 5, 'szolnok')}
                />
              </Card>
            </Col>
          </Row>
        </>
      )}

      {selectedCity === 'kecskemet' && (
        <>
          <Row>
            <Col>
              <Card style={{ padding: '1rem' }}>
                <legend>
                  <b>Első kép</b>
                </legend>
                <FilePickerInput
                  onFileSelect={(file) => setFile6(file)}
                  defaultPicture={mainPagePicture6}
                  onUploadClicked={() => uploadPopUp(file6!, 6, 'kecskemet')}
                />
              </Card>
            </Col>
            <Col>
              <Card style={{ padding: '1rem' }}>
                <legend>
                  <b>Második kép</b>
                </legend>
                <FilePickerInput
                  onFileSelect={(file) => setFile7(file)}
                  defaultPicture={mainPagePicture7}
                  onUploadClicked={() => uploadPopUp(file7!, 7, 'kecskemet')}
                />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Card style={{ padding: '1rem' }}>
                <legend>
                  <b>Harmadik kép</b>
                </legend>
                <FilePickerInput
                  onFileSelect={(file) => setFile8(file)}
                  defaultPicture={mainPagePicture8}
                  onUploadClicked={() => uploadPopUp(file8!, 8, 'kecskemet')}
                />
              </Card>
            </Col>
            <Col lg={6}>
              <Card style={{ padding: '1rem' }}>
                <legend>
                  <b>Negyedik kép</b>
                </legend>
                <FilePickerInput
                  onFileSelect={(file) => setFile9(file)}
                  defaultPicture={mainPagePicture9}
                  onUploadClicked={() => uploadPopUp(file9!, 9, 'kecskemet')}
                />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Card style={{ padding: '1rem' }}>
                <legend>
                  <b>Ötödik kép</b>
                </legend>
                <FilePickerInput
                  onFileSelect={(file) => setFile10(file)}
                  defaultPicture={mainPagePicture10}
                  onUploadClicked={() => uploadPopUp(file10!, 10, 'kecskemet')}
                />
              </Card>
            </Col>
            <Col lg={6}>
              <Card style={{ padding: '1rem' }}>
                <legend>
                  <b>Hatodik kép</b>
                </legend>
                <FilePickerInput
                  onFileSelect={(file) => setFile11(file)}
                  defaultPicture={mainPagePicture11}
                  onUploadClicked={() => uploadPopUp(file11!, 11, 'kecskemet')}
                />
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ModifyMainPageMobile;
