import { WorkerDispatchType, WorkerState } from "./WorkerTypes";

const initState: WorkerState = {
    workers: [],
    users: [],
    usersStatistics: [],
    workersStatistics: [],
    categoryStatistics: [],
    paymentStatistics: [],
}

const WorkerReducer = (state = initState, action: WorkerDispatchType): WorkerState => {
    switch (action.type) {
        case "WORKERS_DOWNLOADED":
            return {
                ...state,
                workers: action.payloadUsers!,
            };
        case "USERS_DOWNLOADED":
            return {
                ...state,
                users: action.payloadUsers!,
            };
        case "USER_STATISTICS_DOWNLOADED":
            return {
                ...state,
                usersStatistics: action.payloadUserStat!,
            };
        case "WORKER_STATISTICS_DOWNLOADED":
            return {
                ...state,
                workersStatistics: action.payloadWorkerStat!,
            };
        case "CATEGORY_STATISTICS_DOWNLOADED":
            return {
                ...state,
                categoryStatistics: action.payloadCategoryStatistics!,
            };
        case "PAYMENT_STATISTICS_DOWNLOADED":
            return {
                ...state,
                paymentStatistics: action.payloadPaymentStatistics!,
            };
        case "WORKER_ADDED":
            return {
                ...state,
                workers: state.workers.concat(action.payloadUser!)
            }
        case "WORKER_DELETED": {
            return {
                ...state,
                workers: state.workers.filter(p => p.id !== action.payloadUserId)
            }
        }
        case "WORKER_MODIFIED": {
            const i = state.workers.findIndex(p => p.id === action.payloadUser?.id)
            state.workers.splice(i, 1, action.payloadUser!)
            return {
                ...state,
            }
        }
        default:
            return state
    }
}
export default WorkerReducer
