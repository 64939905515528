import hu from 'date-fns/locale/hu';
import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { connect, ConnectedProps } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { CategoryStaticstic } from '../components/statistics/CategoryStaticstic';
import { PaymentStaticstic } from '../components/statistics/PaymentStaticstic';
import { UserOrderStatisctics } from '../components/statistics/UserOrderStatistics';
import { WorkerOrderPreparationStaticstic } from '../components/statistics/WorkerOrderPreparationStaticstic';
import { OrderActions } from '../redux/orders/OrderActions';
import { RootState } from '../redux/store';
import { WorkerActions } from '../redux/workers/WorkerActions';
registerLocale('hu', hu);

const initDate = {
  from: new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000),
  to: new Date(),
};
const StatisticsScreen = (props: Props) => {
  const [date, setDate] = useState(initDate);
  const {
    fetchUserStatistics,
    fetchWorkerStatistics,
    fetchCategoryStatistics,
    fetchPaymentStatistics,
  } = props.userActions;

  useEffect(() => {
    fetchWorkerStatistics();
    fetchUserStatistics();
    fetchCategoryStatistics(date.from, date.to);
    fetchPaymentStatistics(date.from, date.to);
  }, [
    fetchWorkerStatistics,
    fetchUserStatistics,
    fetchCategoryStatistics,
    fetchPaymentStatistics,
    date.from,
    date.to,
  ]);

  return (
    <div>
      <h1>Statisztikák</h1>
      <Row>
        <Col lg={2}>
          <DatePicker
            locale='hu'
            selected={date.from}
            onChange={(e) => setDate({ ...date, from: e! })}
            maxDate={new Date()}
            dateFormat='yyyy. MMMM d.'
          />
          -tól
        </Col>
        <Col lg={2}>
          <DatePicker
            locale='hu'
            selected={date.to}
            onChange={(e) => setDate({ ...date, to: e! })}
            maxDate={new Date()}
            dateFormat='yyyy. MMMM d.'
          />
          -ig
        </Col>
      </Row>

      <Row>
        <Col>
          <h4>Kiválasztott időszakra vonatkozó statisztikák</h4>
        </Col>
      </Row>

      <Row>
        <Col>
          <CategoryStaticstic categories={props.categoryStatistics} />
        </Col>
        <Col>
          <PaymentStaticstic payments={props.paymentStatistics} />
        </Col>
      </Row>

      <Row>
        <Col>
          <h4>Összesített statisztikák</h4>
        </Col>
      </Row>

      <Row>
        <Col>
          <WorkerOrderPreparationStaticstic workers={props.workers} />
        </Col>
        <Col>
          <UserOrderStatisctics users={props.users} />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    orders: state.orderReducer.orders,
    categoryStatistics: state.userReducer.categoryStatistics,
    paymentStatistics: state.userReducer.paymentStatistics,
    workers: state.userReducer.workersStatistics,
    users: state.userReducer.usersStatistics,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    orderActions: OrderActions(dispatch),
    userActions: WorkerActions(dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;
export default connector(StatisticsScreen);
