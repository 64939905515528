import React, { FormEvent, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Container,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import { CategoryTreeView } from '../../components/category/CategoryTreeView';
import { FilePickerInput } from '../../components/FilePickerInput';
import { ProductAdditionView } from '../../components/products/ProductAdditionView';
import { ProductContentView } from '../../components/products/ProductContentView';
import { ProductDefaultView } from '../../components/products/ProductDefaultView';
import {
  CommonType,
  ToppingModal,
} from '../../components/products/ToppingModal';
import { ProductActions } from '../../redux/products/ProductActions';
import {
  ICategory,
  IProduct,
  IProductAddition,
  IProductContent,
  IProductDefaults,
} from '../../redux/products/ProductTypes';
import { RootState } from '../../redux/store';

const initialProduct: IProduct = {
  isAvailable: true,
  name: '',
  contents: [],
  additionalItems: [],
  defaultContents: [],
  isModifiable: false,
  isSoldSeparately: true,
  shouldBillItems: false,
  vatRate: 18,
  loyaltyPointsRatio: 10,
  isVoucher: false,
  isRedemption: false,
};

function flatMapCategories(childCategories: ICategory[]): ICategory[] {
  if (!childCategories || childCategories.length === 0) return [];
  const map = childCategories;
  return map.concat(flatMapCategories(map.flatMap((c) => c.childCategories)));
}

enum ModalMode {
  CONTENT,
  ADDITIONS,
  DEFAULTS,
}

const NewProductScreen = (props: Props) => {
  const { fetchAllCategory, fetchAllProduct } = props.productActions;
  useEffect(() => {
    fetchAllCategory();
    fetchAllProduct();
  }, [fetchAllCategory, fetchAllProduct]);
  const { id, defaultCat } = useParams();
  const history = useHistory();

  const mappedCategories = flatMapCategories(props.categories);

  let loadedProd = initialProduct;
  if (id) {
    loadedProd = props.products.find((p) => p.id === parseInt(id!))!;
  } else if (defaultCat && defaultCat !== '-1') {
    loadedProd = initialProduct;
    loadedProd.parentCategory = mappedCategories.find(
      (c) => c.id === parseInt(defaultCat!)
    );
    loadedProd.parentCategoryId = loadedProd.parentCategory?.id;
  }

  const [file, setFile] = useState<File>();
  const [product, setProduct] = useState<IProduct>(loadedProd);
  const [modal, setModal] = useState<boolean>(false);
  const [cityId, setCityId] = useState<number>(product.parentCategoryId!);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.CONTENT);

  async function addProduct(event: FormEvent) {
    event.preventDefault();
    if (id) await props.productActions.modifyProduct(product, file);
    else await props.productActions.addNewProduct(product, file);
    history.goBack();
  }

  const toggleModal = (event?: any) => {
    event && setModalMode(event.target.value);
    setModal(!modal);
  };

  function onModalSaved(savedContent: CommonType[]) {
    switch (+modalMode) {
      case ModalMode.CONTENT:
        setProduct({
          ...product,
          contents: savedContent.map((c) => {
            return { ...c, quantity: 1 };
          }),
        });
        break;
      case ModalMode.ADDITIONS:
        setProduct({
          ...product,
          additionalItems: savedContent.map((c) => {
            return { ...c, maxQuantity: 2, minQuantity: 0 };
          }),
        });
        break;
      case ModalMode.DEFAULTS:
        setProduct({
          ...product,
          defaultContents: savedContent.map((c) => {
            return { ...c, quantity: 1 };
          }),
        });
        break;
      default:
        break;
    }
    setModal(!modal);
  }

  function handleOnContentChange(index: number, content: IProductContent) {
    let prod = { ...product };
    prod.contents[index] = content;
    setProduct(prod);
  }

  function handleOnDefaultChange(index: number, content: IProductDefaults) {
    let prod = { ...product };
    prod.defaultContents[index] = content;
    setProduct(prod);
  }

  function handleOnAdditionChange(index: number, addition: IProductAddition) {
    let prod = { ...product };
    prod.additionalItems[index] = addition;
    setProduct(prod);
  }

  function getProductContent(): any[] {
    switch (+modalMode) {
      case ModalMode.CONTENT:
        return product.contents;
      case ModalMode.ADDITIONS:
        return product.additionalItems;
      case ModalMode.DEFAULTS:
        return product.defaultContents;
      default:
        return [];
    }
  }

  function handleCategoryChange(e: ICategory) {
    if (cityId !== e.parentCategoryId) {
      setCityId(e.parentCategoryId!);
      let prod = { ...product };
      prod.contents = [];
      prod.additionalItems = [];
      setProduct(prod); // React not re-render map if i set the items empty,
    }
    setProduct({ ...product, parentCategoryId: e.id });
  }

  return (
    <Container>
      <Button onClick={() => history.goBack()}>Vissza</Button>
      <Form onSubmit={addProduct}>
        <h1>{!id ? 'Új termék hozzáadása' : 'Meglévő termék szerkesztése'}</h1>

        <FormGroup>
          <legend>Kategória</legend>
          <CategoryTreeView
            categories={props.categories}
            onChange={handleCategoryChange}
            mode='radioSelect'
            showItems
            defaultSelected={product.parentCategory}
          />
        </FormGroup>
        {product.parentCategoryId && (
          <>
            <CustomInput
              checked={product.isRedemption}
              onChange={(e) =>
                setProduct({ ...product, isRedemption: !product.isRedemption })
              }
              type='switch'
              id='Voucher'
              label='Visszaváltási díj'
            />
            <CustomInput
              checked={product.isVoucher}
              onChange={(e) =>
                setProduct({ ...product, isVoucher: !product.isVoucher })
              }
              type='switch'
              id='Voucher'
              label='Ajándékutalvány'
            />
            <CustomInput
              checked={product.isModifiable}
              onChange={(e) =>
                setProduct({ ...product, isModifiable: !product.isModifiable })
              }
              type='switch'
              id='Modosithato'
              label='Módosítható-e a termék'
            />
            <CustomInput
              checked={product.isAvailable}
              onChange={(e) =>
                setProduct({ ...product, isAvailable: !product.isAvailable })
              }
              type='switch'
              id='Elerheto'
              label='Elérhető-e a termék jelenleg'
            />
            <CustomInput
              checked={product.isSoldSeparately}
              onChange={(e) =>
                setProduct({
                  ...product,
                  isSoldSeparately: !product.isSoldSeparately,
                })
              }
              type='switch'
              id='Megvasarolhato'
              label='Megvásárolható-e külön, vagy csak mint feltét használható fel.'
            />
            <CustomInput
              checked={product.shouldBillItems}
              onChange={(e) =>
                setProduct({
                  ...product,
                  shouldBillItems: !product.shouldBillItems,
                })
              }
              type='switch'
              id='Kulonszamlazas'
              label={
                <p>
                  A termékhez csatolt termékek (Miből áll össze...) és extra
                  feltétek (Mik az extra ...) önállóan legyenek-e számlázva.
                  FONTOS:{' '}
                  <a href='/szamlazas_utmutato.pdf' target='_blank' download>
                    Info a beállításról
                  </a>
                </p>
              }
            />
            <Row form>
              <Col md={6}>
                <legend>Neve</legend>
                <Input
                  type='text'
                  required
                  value={product?.name}
                  onChange={(e) =>
                    setProduct({ ...product, name: e.target.value })
                  }
                />
              </Col>
              <Col md={6}>
                <legend>Ára (ft)</legend>
                <Input
                  required
                  type='number'
                  value={product.price}
                  onChange={(e) =>
                    setProduct({ ...product, price: parseInt(e.target.value) })
                  }
                />
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FilePickerInput
                  onFileSelect={(file) => setFile(file)}
                  defaultPicture={product.picture}
                  pictureMaxHeight={180}
                />
              </Col>
              <Col>
                <legend>Leírás</legend>
                <Input
                  type='textarea'
                  value={product.description}
                  onChange={(e) =>
                    setProduct({ ...product, description: e.target.value })
                  }
                />
              </Col>
            </Row>

            <Row form>
              <Col md={6}>
                <legend>Számlán szereplő ár</legend>
                <Input
                  type='number'
                  value={product?.billingPrice}
                  onChange={(e) =>
                    setProduct({
                      ...product,
                      billingPrice: parseInt(e.target.value),
                    })
                  }
                />
              </Col>
              <Col md={6}>
                <legend>Áfakulcs (%)</legend>
                <Input
                  min={0}
                  max={100}
                  required
                  type='number'
                  value={product?.vatRate}
                  onChange={(e) =>
                    setProduct({
                      ...product,
                      vatRate: parseInt(e.target.value),
                    })
                  }
                />
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <legend>Hűségpont jóváírás (%)</legend>
                <Input
                  min={0}
                  max={100}
                  required
                  type='number'
                  value={product?.loyaltyPointsRatio}
                  onChange={(e) =>
                    setProduct({
                      ...product,
                      loyaltyPointsRatio: parseInt(e.target.value),
                    })
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <legend>Miből áll össze a termék</legend>
                <Button
                  color='success'
                  value={ModalMode.CONTENT}
                  onClick={toggleModal}
                >
                  + Összetevő
                </Button>
                <Card color='light' style={{ padding: '1rem' }}>
                  {product.contents.map((prodContent, index) => (
                    <ProductContentView
                      key={prodContent.itemId}
                      onContentChanged={handleOnContentChange}
                      index={index}
                      prodContent={prodContent}
                      onDeleteClicked={() =>
                        setProduct({
                          ...product,
                          contents: product.contents?.filter(
                            (it) => it !== prodContent
                          ),
                        })
                      }
                    />
                  ))}
                </Card>
              </Col>
              <Col>
                <legend>Mik az extra feltétek</legend>
                <Button
                  color='success'
                  value={ModalMode.ADDITIONS}
                  onClick={toggleModal}
                >
                  + Választható feltét
                </Button>
                <Card color='light' style={{ padding: '1rem' }}>
                  {product.additionalItems.map((prodContent, index) => (
                    <ProductAdditionView
                      key={prodContent.itemId}
                      onAdditionChange={handleOnAdditionChange}
                      index={index}
                      prodContent={prodContent}
                      onDeleteClicked={() =>
                        setProduct({
                          ...product,
                          additionalItems: product.additionalItems?.filter(
                            (it) => it !== prodContent
                          ),
                        })
                      }
                    />
                  ))}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <legend>Mik az alapértelmezett termékek (kreátorhoz)</legend>
                <Button
                  color='success'
                  value={ModalMode.DEFAULTS}
                  onClick={toggleModal}
                >
                  + Alapértelmezett feltét
                </Button>
                <Card color='light' style={{ padding: '1rem' }}>
                  {product.defaultContents.map((prodContent, index) => (
                    <ProductDefaultView
                      key={prodContent.itemId}
                      onContentChanged={handleOnDefaultChange}
                      index={index}
                      prodContent={prodContent}
                      onDeleteClicked={() =>
                        setProduct({
                          ...product,
                          defaultContents: product.defaultContents?.filter(
                            (it) => it !== prodContent
                          ),
                        })
                      }
                    />
                  ))}
                </Card>
              </Col>
            </Row>

            <ToppingModal
              defaultProdContent={getProductContent()}
              closeModal={toggleModal}
              modal={modal}
              onSaveClicked={(e) => onModalSaved(e)}
              availableCategories={props.categories}
              availableProducts={props.products.filter(
                (p) => p.id !== parseInt(id!)
              )}
              doNotShowCats={+modalMode === ModalMode.DEFAULTS}
            />

            <Button type='submit' color='primary'>
              {!id ? 'Termék felvétele' : 'Mentés'}
            </Button>
          </>
        )}
      </Form>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    products: state.productReducer.products,
    categories: state.productReducer.categories,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    productActions: ProductActions(dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;
export default connector(NewProductScreen);
