import React, { FormEvent, useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Alert, Button, Card, Container, Form, FormGroup, Input, Label } from 'reactstrap';
import LoggedOutNavbar from '../components/navbar/LoggedOutNavbar';
import { AuthActions } from '../redux/auth/AuthActions';
import { FormData } from '../redux/auth/AuthTypes';
import { RootState } from '../redux/store';

const defaultFormData: FormData = {
    email: "",
    password: "",
    isStayLoggedIn: true
}
const LoginScreen = (props: Props) => {
    const [formData, setFormData] = useState<FormData>(defaultFormData)
    const [isError, setError] = useState<boolean>(false)
    let history = useHistory();

    async function signIn(e: FormEvent) {
        e.preventDefault()
        setError(false)
        await props.AuthActions.signIn(formData)
            .catch(() => setError(true))
    }

    useEffect(() => {
        if (props.isLoggedIn) {
            history.replace("/dashboard/orders")
        }
    }, [props.isLoggedIn, history])
    if(props.isLoggedIn) return null

    return (<>
        <LoggedOutNavbar />
        <Container>
            <Card body style={{ marginTop: "5rem", backgroundColor: "#eeeeee" }}
                className="col-sm-12 col-md-6 offset-md-3">
                <Form onSubmit={signIn}>
                    <h1>Bejelentkezés</h1>
                    <FormGroup>
                        <Label>Email cím / felhasználónév</Label>
                        <Input type="text"
                            value={formData.email}
                            onChange={e => setFormData({ ...formData, email: e.target.value })}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="examplePassword">Jelszó</Label>
                        <Input type="password"
                            onChange={e => setFormData({ ...formData, password: e.target.value })}
                        />
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="checkbox"
                                defaultChecked={true}
                                onChange={e => setFormData({ ...formData, isStayLoggedIn: e.target.checked })}
                            />Emlékezz rám
                            </Label>
                    </FormGroup>
                    <Button color="primary" type="submit">
                        Bejelentkezés
                        </Button>
                    {isError &&
                        <Alert isOpen={isError} toggle={() => setError(!isError)} color="danger"><b>Hiba!</b> Nem
                            sikerült a bejelentkezés</Alert>}
                </Form>
            </Card>
        </Container>
    </>
    );
}
const mapStateToProps = (state: RootState) => {
    return {
        isLoggedIn: state.authReducer.isLoggedIn
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        AuthActions: AuthActions(dispatch)
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type Props = ConnectedProps<typeof connector>
export default connector(LoginScreen)
