import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Container, Navbar } from 'reactstrap';
import { AuthActions } from '../../redux/auth/AuthActions';
import { RootState } from '../../redux/store';
import { NavMenu } from '../MobileNav';

const LoggedInNavbar = (props: any) => {
  return (
    <Navbar expand='lg' color='primary'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          width: '100%',
          paddingRight: 10,
        }}
      >
        {' '}
        <NavMenu {...props} />
      </div>
    </Navbar>
  );
};
const mapStateToProps = (state: RootState) => {
  return {
    isLoggedIn: state.authReducer.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    AuthActions: AuthActions(dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;
export default connector(LoggedInNavbar);
