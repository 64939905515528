import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col } from 'reactstrap';
import OrderItemView from '../../components/orders/OrderItemView';
import { AuthActions } from '../../redux/auth/AuthActions';
import { RootState } from '../../redux/store';


const OrderPreparationScreen = (props: Props) => {
    const {id} = useParams();
    const item = props.orders?.find(e => e.id === parseInt(id!))
    return (<>
            <h1>Rendelés feldolgozása: {id}</h1>
            <Col lg={6}>{item ? <OrderItemView order={item!}/> : <h1>Nincs ilyen rendelés!</h1>}</Col>
        </>
    )
}
const mapStateToProps = (state: RootState) => {
    return {
        orders: state.orderReducer.orders
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        AuthActions: AuthActions(dispatch)
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type Props = ConnectedProps<typeof connector>
export default connector(OrderPreparationScreen)
