import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import LoggedInNavbar from '../components/navbar/LoggedInNavbar';
import Sidebar from '../components/sidebar/Sidebar';
import { configureSocketIo, socket } from '../configureSocketIo';
import { AuthActions } from '../redux/auth/AuthActions';
import { OrderActions } from '../redux/orders/OrderActions';
import { RootState } from '../redux/store';
import ArchiveScreen from './ArchiveScreen';
import CategoryScreen from './categories/CategoryScreen';
import NewCategoryScreen from './categories/NewCategoryScreen';
import CouponsScreen from './coupons/CouponsScreen';
import NewCouponScreen from './coupons/NewCouponScreen';
import CustomersScreen from './customers/CustomersScreen';
import ModifyCustomerScreen from './customers/ModifyCustomerScreen';
import LogoutScreen from './LogoutScreen';
import ModifyMainPageScreen from './ModifyMainPageScreen';
import ModifyMainPageMobile from './ModifyMainPageMobile';
import OrderPreparationScreen from './orders/OrderPreparationScreen';
import OrdersScreen from './orders/OrdersScreen';
import PopupScreen from './PopupScreen';
import EmailsScreen from './EmailsScreen';
import NewProductScreen from './products/NewProductScreen';
import ProductsScreen from './products/ProductsScreen';
import ShippingScreen from './ShippingScreen';
import StatisticsScreen from './StatisticsScreen';
import NewWorkerScreen from './workers/NewWorkerScreen';
import WorkersScreen from './workers/WorkersScreen';
import NewsScreen from './news/NewsScreen';
import { ProductActions } from '../redux/products/ProductActions';
import PromotionScreen from './PromotionScreen';

const DashboardScreen = (props: Props) => {
  const { path } = useRouteMatch();

  useEffect(() => {
    configureSocketIo(props?.OrderActions);

    setTimeout(() => {
      props?.CategoryActions?.fetchAllCategory();
    }, 10);

    return () => {
      console.log('Socket disonncted');
      socket.disconnect();
    };
  }, [props.CategoryActions, props.OrderActions]);

  // Guard
  if (!props.isLoggedIn) {
    return <Redirect to={{ pathname: '/login' }} />;
  }

  const routes = [
    { path: `${path}/orders`, name: 'Beérkezett rendelések' },
    { path: `${path}/archive`, name: 'Archívum' },
  ];

  const user = props.userSession!;

  if (user.type === 'ADMIN') {
    routes.push(
      { path: `${path}/admin_products`, name: 'Termékek szerkesztése' },
      { path: `${path}/admin_categories`, name: 'Kategóriák szerkesztése' },
      { path: `${path}/admin_coupons`, name: 'Kuponok' },
      { path: `${path}/admin_news`, name: 'Hírek szerkesztése' },
      {
        path: `${path}/admin_main_page`,
        name: 'Főoldal (desktop)',
      },
      {
        path: `${path}/admin_main_mobile_page`,
        name: 'Főoldal (mobil)',
      },
      { path: `${path}/admin_popups`, name: 'Szórólap és popup szerkesztése' },
      { path: `${path}/admin_workers`, name: 'Dolgozók kezelése' },
      { path: `${path}/admin_statistics`, name: 'Statisztikák' },
      { path: `${path}/admin_customers`, name: 'Vásárlók szerkesztése' },
      { path: `${path}/admin_shipping`, name: 'Kiszállítási díj állítása' },
      {
        path: `${path}/admin_promotion`,
        name: 'Promóciós termék és extra árak',
      }
    );
  }
  routes.push({ path: `${path}/logout`, name: 'Kijelentkezés' });

  return (
    <>
      <Sidebar {...props} routes={routes} bgColor='black' activeColor='red' />

      <div className='main-panel'>
        <LoggedInNavbar routes={routes} />
        <div style={{ margin: '1rem' }}>
          <Switch>
            <Route exact path={`${path}/orders`} component={OrdersScreen} />
            <Route
              path={`${path}/orders/:id`}
              component={OrderPreparationScreen}
            />

            <Route path={`${path}/archive`} component={ArchiveScreen} />

            <Route
              exact
              path={`${path}/admin_products`}
              component={ProductsScreen}
            />
            <Route
              exact
              path={`${path}/admin_products/newproduct/:defaultCat`}
              component={NewProductScreen}
            />
            <Route
              exact
              path={`${path}/admin_products/updateproduct/:id`}
              component={NewProductScreen}
            />

            <Route
              exact
              path={`${path}/admin_categories`}
              component={CategoryScreen}
            />
            <Route
              exact
              path={`${path}/admin_categories/newcategory/:defaultCat`}
              component={NewCategoryScreen}
            />
            <Route
              exact
              path={`${path}/admin_categories/updatecategory/:id/`}
              component={NewCategoryScreen}
            />

            <Route
              exact
              path={`${path}/admin_coupons`}
              component={CouponsScreen}
            />
            <Route
              exact
              path={`${path}/admin_coupons/newcoupon`}
              component={NewCouponScreen}
            />
            <Route
              exact
              path={`${path}/admin_coupons/updatecoupon/:id/:clone`}
              component={NewCouponScreen}
            />
            <Route
              exact
              path={`${path}/admin_coupons/copycoupon/:id/:clone`}
              component={NewCouponScreen}
            />

            <Route exact path={`${path}/admin_news`} component={NewsScreen} />
            <Route
              exact
              path={`${path}/admin_main_page`}
              component={ModifyMainPageScreen}
            />
            <Route
              exact
              path={`${path}/admin_main_mobile_page`}
              component={ModifyMainPageMobile}
            />
            <Route
              exact
              path={`${path}/admin_popups`}
              component={PopupScreen}
            />
            <Route
              exact
              path={`${path}/admin_emails`}
              component={EmailsScreen}
            />
            <Route
              exact
              path={`${path}/admin_statistics`}
              component={StatisticsScreen}
            />

            <Route
              exact
              path={`${path}/admin_workers`}
              component={WorkersScreen}
            />
            <Route
              exact
              path={`${path}/admin_workers/newworker`}
              component={NewWorkerScreen}
            />
            <Route
              exact
              path={`${path}/admin_workers/updateworker/:id`}
              component={NewWorkerScreen}
            />

            <Route
              exact
              path={`${path}/admin_customers`}
              component={CustomersScreen}
            />
            <Route
              exact
              path={`${path}/admin_customers/updatecustomer/:id`}
              component={ModifyCustomerScreen}
            />

            <Route
              exact
              path={`${path}/admin_shipping`}
              component={ShippingScreen}
            />
            <Route
              exact
              path={`${path}/admin_promotion`}
              component={PromotionScreen}
            />

            <Route path={`${path}/logout`} component={LogoutScreen} />
          </Switch>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: RootState) => {
  return {
    isLoggedIn: state.authReducer.isLoggedIn,
    token: state.authReducer.token,
    userSession: state.authReducer.userSession,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    AuthActions: AuthActions(dispatch),
    OrderActions: OrderActions(dispatch),
    CategoryActions: ProductActions(dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;
export default connector(DashboardScreen);
