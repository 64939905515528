//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Col, Row, Table, Input } from 'reactstrap';
import CouponItemView from '../../components/coupons/CouponItemView';
import { CouponActions } from '../../redux/coupons/CouponActions';
import { RootState } from '../../redux/store';

const CouponsScreen = (props: Props) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { fetchCoupons, fetchWeeks, modifyWeeks } = props.couponActions;

  const goToCouponScreen = () => {
    history.push(`${path}/newcoupon`);
  };

  const getWeeks = (week: number) => {
    const x = Object.entries(props.weeks[week] ?? {});
    if (x.length !== 0) {
      return x.filter(([n, val]) => n !== 'id');
    }
  };

  const [week1, setWeek1] = useState();
  const [week2, setWeek2] = useState();
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    modifyWeeks(week1, week2);
    alert('Sikeres mentés!');
  };

  useEffect(() => {
    fetchCoupons();
    fetchWeeks();
  }, [fetchCoupons, fetchWeeks]);

  useEffect(() => {
    setWeek1(getWeeks(0));
    setWeek2(getWeeks(1));
  }, [props]);

  const handler1 = (e: any) => {
    setWeek1(
      week1!.map((item) => {
        if (item[0] === e.target.name) {
          return [item[0], e.target.value];
        }
        return item;
      })
    );
  };

  const handler2 = (e: any) => {
    setWeek2(
      week2!.map((item) => {
        if (item[0] === e.target.name) {
          return [item[0], e.target.value];
        }
        return item;
      })
    );
  };

  return (
    <div>
      <h2>Kupon hetek</h2>
      <p>
        Add meg, hogy melyik kupont melyik nap szeretnéd promótálni az
        azonosítója alapján. Pl.: 12 Ha több kupont is szeretnél egy nap
        promótálni add meg az azonosítójukat vesszővel elválasztva. Pl.:
        12,13,14
      </p>
      <Table>
        <thead>
          <tr>
            <th>Hét</th>
            <th>Vasárnap</th>
            <th>Hétfő</th>
            <th>Kedd</th>
            <th>Szerda</th>
            <th>Csütörtök</th>
            <th>Péntek</th>
            <th>Szombat</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope='row'>Páratlan</th>
            {week1 &&
              week1.map(([n, val]: any) => (
                <td>
                  <Input
                    type='text'
                    name={n}
                    value={val}
                    onChange={(e) => handler1(e)}
                  />
                </td>
              ))}
          </tr>
          <tr>
            <th scope='row'>Páros</th>
            {week2 &&
              week2?.map(([n, val]: any) => (
                <td>
                  <Input
                    type='text'
                    name={n}
                    value={val}
                    onChange={(e) => handler2(e)}
                  />
                </td>
              ))}
          </tr>
        </tbody>
      </Table>
      <Button color='success' onClick={handleSubmit}>
        Mentés
      </Button>
      <h2>Kuponok</h2>
      <Button color='success' onClick={goToCouponScreen}>
        Új kupon hozzáadása
      </Button>
      {props.coupons && (
        <Row>
          {props.coupons
            .sort((a, b) => a.id - b.id)
            .map((c, index) => (
              <Col lg={5} key={index}>
                {' '}
                <CouponItemView coupon={c} />
              </Col>
            ))}
        </Row>
      )}
      {!props.coupons.length && <legend>Nincs létrehozva még kupon!</legend>}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    coupons: state.couponReducer.coupons,
    weeks: state.couponReducer.weeks,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    couponActions: CouponActions(dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;
export default connector(CouponsScreen);
