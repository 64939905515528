import React, { useState } from 'react'
import DropdownTreeSelect, { Mode, TreeNode } from 'react-dropdown-tree-select'
import { ICategory } from '../../redux/products/ProductTypes'
type IOption = { label: string, value: ICategory, children?: IOption[] }

type Props = {
    categories: ICategory[],
    mode: Mode,
    onChange: (cat: ICategory, cats: ICategory[]) => void,
    required?: boolean,
    showItems?: boolean,
    defaultSelected?: ICategory[] | ICategory
}

function isCategoryArray(pet: ICategory | ICategory[]): pet is ICategory[] {
    return (pet as ICategory[])?.length !== undefined;
}

export function CategoryTreeView(props: Props) {
    const { categories, mode, onChange, showItems, defaultSelected } = props

    let defaultSelectedlist: any = defaultSelected
    if (!isCategoryArray(defaultSelected ?? []))
        defaultSelectedlist = [defaultSelected]

    const [cats, setCats] = useState<ICategory[]>(defaultSelectedlist ?? [])


    function convertToTreeview(childCategories: ICategory[], showItems?: boolean, selectedCatIds?: number[]): IOption[] {
        if (!childCategories || childCategories.length === 0) return []
        return childCategories?.flatMap(c => {
            return {
                label: !showItems ? c.name : `${c.name} (${c.items?.length ?? 0} termék elérhető)`,
                value: c,
                checked: selectedCatIds?.includes(c.id!),
                children: convertToTreeview(c.childCategories, showItems, selectedCatIds)
            }
        })
    }

    const data = convertToTreeview(categories, showItems, cats?.map(c => c.id!))

    function handleCategoryChange(cat: TreeNode, cats: TreeNode[]) {
        //@ts-ignore
        const catss = cats.map(c => c.value) as ICategory[]
        //@ts-ignore
        onChange(cat.value as ICategory, catss as ICategory[])
        setCats(catss)
    }

    return (
        <>
            <DropdownTreeSelect
                data={data}
                keepTreeOnSearch
                texts={{ placeholder: "Válassz/keress kategóriát", noMatches: "Nem található a keresésnek megfelelő kategória" }}
                mode={mode}
                //@ts-ignore
                onChange={handleCategoryChange}
                 />

            {props.required && <input
                tabIndex={-1}
                autoComplete="off"
                style={{ opacity: 0, height: 0, }}
                value={cats[0]?.id ?? ''}
                required={props.required}
            />}
        </>
    )
}