import React from 'react';
import { Card, CardText } from 'reactstrap';
import { PaymentStat } from '../../redux/workers/WorkerTypes';

type Props = {
  payments: PaymentStat[];
};

function getPaymentName(type: string): string {
  const paymentName: Record<string, string> = {
    OTPDIRECT: 'Szép kártya',
    CARD: 'Online bakkártya',
    CARD_LOCATION: 'Kártyás fizetés helyszínen',
    CASH: 'Készpénz',
  };
  const name = paymentName[type] ?? type;
  return name;
}

export const PaymentStaticstic = (props: Props) => {
  return (
    <>
      <h5>Fizetési statisztika</h5>
      {props.payments.map((u, i) => (
        <Card key={i} style={{ padding: '0.5rem' }}>
          <CardText>
            <strong>{i + 1}. </strong>
            <span>{getPaymentName(u.payment)}</span>
          </CardText>
          <CardText>Fizetések: {u.occurance} db</CardText>
        </Card>
      ))}
    </>
  );
};
