import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { IProductContent } from '../../redux/products/ProductTypes'

type Props = {
    prodContent: IProductContent,
    index: number,
    onDeleteClicked: () => void
    onContentChanged: (index: number, newProduct: IProductContent) => void
}

export const ProductContentView = (props: Props) => {
    const {prodContent, index, onDeleteClicked} = props;
    
    function getFullProductName(product: any): string {
        let name = '';
        if (product)
            product.parentCategory ? name =  getFullProductName(product.parentCategory) + '/' + product.name : name = product.name;
        return name;
    }

    return (
        <div key={prodContent.item?.id ?? prodContent.category?.id}>
            {index !== 0 && <hr style={{width: "100%", textAlign: "left", marginLeft: 0}}/>}
            <Row className="align-items-center">
                <Col>
                    <h6>{getFullProductName(prodContent.item ?? prodContent.category)}</h6>
                    Azon:{prodContent.itemId ?? prodContent.categoryId}
                </Col>
                <Col className="col-lg-auto">
                    <Row className="align-items-center">
                        <Col><Button onClick={() => prodContent.quantity > 1 && props.onContentChanged(index, {
                            ...prodContent,
                            quantity: prodContent.quantity - 1
                        })} color="info">-</Button></Col>
                        <Col><h6>{prodContent.quantity} db</h6></Col>
                        <Col><Button onClick={() => prodContent.quantity < 9 && props.onContentChanged(index, {
                            ...prodContent,
                            quantity: prodContent.quantity + 1
                        })} color="info">+</Button></Col>
                    </Row>
                </Col>
                <Col>
                    <Button onClick={onDeleteClicked} color="danger"><span><FontAwesomeIcon size="2x"
                                                                                            icon={faTimes}/></span>
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

