import hu from 'date-fns/locale/hu';
import React, { FormEvent, useEffect, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Container,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
} from 'reactstrap';
import { CategoryTreeView } from '../../components/category/CategoryTreeView';
import { FilePickerInput } from '../../components/FilePickerInput';
import { ProductActions } from '../../redux/products/ProductActions';
import { ICategory } from '../../redux/products/ProductTypes';
import { RootState } from '../../redux/store';

registerLocale('hu', hu);

const getInitialCategory = (): ICategory => {
  return {
    childCategories: [],
    isCity: false,
    items: [],
    name: '',
    isHiddenInFrontend: false,
    isModifiable: false,
  };
};

function flatMapCategories(
  childCategories: ICategory[] | undefined
): ICategory[] {
  if (!childCategories || childCategories.length === 0) return [];
  const map = childCategories ?? [];
  return map.concat(flatMapCategories(map.flatMap((c) => c.childCategories)));
}

const NewCategoryScreen = (props: Props) => {
  let { id, defaultCat } = useParams();

  let loadedCategory: ICategory | undefined;
  const mappedCategories = flatMapCategories(props.categories);

  if (id) {
    loadedCategory = mappedCategories.find((p) => p.id === parseInt(id!));
  } else if (defaultCat && defaultCat !== '-1') {
    loadedCategory = getInitialCategory();
    loadedCategory.parentCategoryId = mappedCategories.find(
      (c) => c.id === parseInt(defaultCat!)
    )?.id;
  }
  const [category, setCategory] = useState<ICategory>(
    loadedCategory ?? getInitialCategory()
  );
  const [file, setFile] = useState<File>();
  const history = useHistory();

  async function addNewCategory(e: FormEvent) {
    e.preventDefault();
    if (id) await props.productActions.modifyCategory(category, file);
    else await props.productActions.addNewCategory(category, file);
    history.goBack();
  }

  useEffect(() => {
    props.productActions.fetchAllCategory();
    props.productActions.fetchAllProduct();
  }, [props.productActions]);

  return (
    <Container>
      <Button onClick={() => history.goBack()}>Vissza</Button>
      <h1>
        {!id
          ? 'Új kategória hozzáadása'
          : `${loadedCategory?.name} kategória szerkesztése`}
      </h1>

      <Form onSubmit={addNewCategory}>
        <FormGroup>
          <legend>Szülő kategóriája</legend>

          <CategoryTreeView
            categories={props.categories}
            onChange={(cat, _) =>
              setCategory({ ...category, parentCategoryId: cat.id })
            }
            mode='radioSelect'
            defaultSelected={mappedCategories.find(
              (c) => c.id === category.parentCategoryId
            )}
            required
          />
        </FormGroup>
        <Row from>
          <Col>
            <FormGroup>
              <legend>Neve</legend>
              <Input
                type='text'
                value={category?.name}
                onChange={(e) =>
                  setCategory({ ...category, name: e.target.value })
                }
                required
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <legend>Komment / leírás</legend>
              <Input
                type='textarea'
                value={category?.comment}
                onChange={(e) =>
                  setCategory({ ...category, comment: e.target.value })
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <CustomInput
            checked={category.isHiddenInFrontend}
            onChange={(e) =>
              setCategory({ ...category, isHiddenInFrontend: e.target.checked })
            }
            type='switch'
            id='Elérhető'
            label='Ne legyen listázva a honlapon, mint alketagória (Érdemes akkor bekapcsolni, ha például feltét kategóriát készítünk)'
          />
        </FormGroup>

        <FormGroup>
          <CustomInput
            checked={category.isModifiable}
            onChange={(e) =>
              setCategory({ ...category, isModifiable: e.target.checked })
            }
            type='switch'
            id='isModifiable'
            label='Szerkeszthető-e a kategória (isModifiable)'
          />
        </FormGroup>

        <Col lg='6'>
          <FilePickerInput
            defaultPicture={category.picture}
            onFileSelect={(file) => setFile(file)}
          />
        </Col>
        <Button color='primary' type='submit'>
          {!id ? 'Kategória létrehozása' : 'Mentés'}
        </Button>
      </Form>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    products: state.productReducer.products,
    categories: state.productReducer.categories,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    productActions: ProductActions(dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;
export default connector(NewCategoryScreen);
