import Axios from 'axios';
import React, { useState } from 'react';
import { Col, Input, Row, Button, Card } from 'reactstrap';
import { IFile } from '../redux/products/ProductTypes';

type Props = {
  defaultPicture?: IFile;
  onFileSelect: (file: File) => void;
  onUploadClicked?: () => void;
  pictureMaxHeight?: number;
};

export function FilePickerInput(props: Props) {
  const { defaultPicture, onFileSelect, onUploadClicked, pictureMaxHeight } =
    props;
  const [file, setFile] = useState<File>();

  return (
    <>
      <Row className='d-flex align-items-center justify-content-start'>
        <Col>
          <legend>
            Kép feltöltése <br />
            <i>(max 8MB)</i>
          </legend>
        </Col>
        <Col>
          <Input
            type='file'
            id='file'
            name='customFiles'
            onChange={(e) => {
              const selectedFile = e.target.files![0];
              if (!selectedFile) return;
              if (selectedFile.size >= 8000000) {
                alert('A kiválasztott fájl túl nagy! Maximális méret 8MB!');
              }
              setFile(selectedFile);
              onFileSelect(selectedFile);
            }}
          />
        </Col>
      </Row>
      <Row className='d-flex justify-content-around' style={{ margin: '1rem' }}>
        {file && (
          <Card color='warning' style={{ padding: '0.5rem' }}>
            <Col>
              <Row>
                <b style={{ marginRight: '1rem' }}>Kiválasztott (új) kép:</b>
              </Row>
              <Row>
                <img
                  alt=''
                  style={{ maxHeight: pictureMaxHeight ?? 200 }}
                  src={URL.createObjectURL(file)}
                />
              </Row>
            </Col>
          </Card>
        )}

        {defaultPicture && (
          <Card style={{ padding: '0.5rem', backgroundColor: '#eeeeee' }}>
            <Col>
              <Row>
                <b style={{ marginRight: '1rem' }}>Honlapon lévő kép: </b>
              </Row>
              <Row>
                <img
                  alt=''
                  style={{ maxHeight: pictureMaxHeight ?? 200 }}
                  src={
                    Axios.defaults.baseURL + '/files/' + defaultPicture.filePath
                  }
                />
              </Row>
            </Col>
          </Card>
        )}
      </Row>
      {onUploadClicked && (
        <Button
          type='submit'
          color='primary'
          disabled={!file}
          onClick={onUploadClicked}
        >
          Feltöltés
        </Button>
      )}
    </>
  );
}
