import React, { FormEvent, useEffect, useState } from "react"
import { connect, ConnectedProps } from "react-redux"
import Select from 'react-select'
import { Button, Form, FormGroup, Input, Row, Col } from "reactstrap"
import { FilePickerInput } from "../../components/FilePickerInput"
import { NewsActions } from "../../redux/news/NewsActions"
import { INews } from "../../redux/news/NewsTypes"
import { RootState } from "../../redux/store"

const getEmptyNews = () => {
    return {
        body: "",
        header: ""
    } as INews
}
const NewsScreen = (props: Props) => {
    const [selectedNews, setSelectedNews] = useState<INews>()
    const [file, setFile] = useState<File>()
    const { fetchNews } = props.newsActions

    useEffect(() => {
        fetchNews()
    }, [fetchNews])

    async function submit(e: FormEvent) {
        e.preventDefault()
        if (selectedNews!.id) {
            await props.newsActions.modifyNews(selectedNews!, file)
        } else {
            await props.newsActions.addNewNews(selectedNews!, file)
        }
        window.location.reload();
    }

    async function deleteNews(){
        await props.newsActions.deleteNews(selectedNews!)
        window.location.reload();
    }
    return (
        <>
            <h1>Hírek szerkesztése</h1>
            <Button color="success" onClick={() => setSelectedNews(getEmptyNews())}>Új hír hozzáadása</Button>

            <Select
                value={selectedNews}
                options={props.news}
                getOptionValue={i => i.id?.toString() ?? "-1"}
                getOptionLabel={i => i.header}
                onChange={e => setSelectedNews(e as INews)}
                placeholder="Válassz hírt..."
                noOptionsMessage={() => "Sajnos nincs még felvéve hír"}
            />
            {
                selectedNews &&
                <Form onSubmit={submit}>
                    <h2 style={{margin:'1rem'}}>{selectedNews.id ? "Meglévő hír szerkesztése" : "Új hír hozzáadaása"}</h2>
                    <Row form>
                        <Col>
                            <Col>
                                <FormGroup>
                                    <legend>Cím</legend>
                                    <Input type="text"
                                        value={selectedNews?.header}
                                        onChange={e => setSelectedNews({ ...selectedNews, header: e.target.value })}
                                    />
                                </FormGroup>
                            </Col>

                            <Col>
                                <FilePickerInput
                                    onFileSelect={file => setFile(file)}
                                    defaultPicture={selectedNews.picture}
                                />
                            </Col>
                        </Col>

                        <Col lg={6}>
                            <FormGroup>
                                <legend>Törzs</legend>
                                <Input
                                    style={{ minHeight: "350px" }}
                                    type="textarea"
                                    name="text" id="exampleText"
                                    value={selectedNews?.body}
                                    onChange={e => setSelectedNews({ ...selectedNews, body: e.target.value })}
                                />
                            </FormGroup>
                        </Col>

                    </Row>
                    <Button color="primary" type="submit">{!selectedNews.id ? "Hír létrehozása" : "Hír szerkesztése"}</Button>
                    {selectedNews.id && <Button color="danger" onClick={deleteNews}>Hír törlése</Button>}
                </Form >
            }
        </>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        news: state.newsReducer.news,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        newsActions: NewsActions(dispatch)
    }
}
const connector = connect(mapStateToProps, mapDispatchToProps)
type Props = ConnectedProps<typeof connector>
export default connector(NewsScreen)

