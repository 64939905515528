import { faUserPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Button, Col, Row } from 'reactstrap'
import WorkerItemView from '../../components/workers/WorkerItemView'
import { RootState } from '../../redux/store'
import { WorkerActions } from '../../redux/workers/WorkerActions'

const WorkersScreen = (props: Props) => {
    const history = useHistory()
    const { path } = useRouteMatch();
    const { fetchOnlyWorkers } = props.workerActions
    useEffect(() => {
        fetchOnlyWorkers()
    }, [fetchOnlyWorkers])

    function goToNewProduct() {
        history.push(`${path}/newworker`)
    }

    return (
        <>
            <h1>Dolgozók szerkesztése</h1>
            <Button color="success" onClick={goToNewProduct}>Új dolgozó hozzáadása <span><FontAwesomeIcon size="2x"
                icon={faUserPlus} /></span></Button>
            <Row>
                {props.workers.map(w =>
                    <Col lg={5}>
                        <WorkerItemView
                            key={w.id}
                            worker={w}
                            isOwnProfile={JSON.parse(sessionStorage.getItem("user")!).id === w.id} />
                    </Col>)}
            </Row>
        </>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        workers: state.userReducer.workers.filter(i => i.type === "ADMIN" || i.type === "WORKER")
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        workerActions: WorkerActions(dispatch)
    }
}
const connector = connect(mapStateToProps, mapDispatchToProps)
type Props = ConnectedProps<typeof connector>
export default connector(WorkersScreen)

