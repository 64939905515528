import React, { useState, FormEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Container, FormGroup, Input, Label, Form } from 'reactstrap';
import { RootState } from '../../redux/store';
import { IUser } from '../../redux/workers/WorkerTypes';
import { WorkerActions as UserActions } from '../../redux/workers/WorkerActions';
import { CustomerAddressView } from '../../components/customer/CustomerAddressView';

const ModifyCustomerScreen = (props: Props) => {
  let { id } = useParams();
  const loadedCustomer = props.customers.find((p) => p.id === parseInt(id!));
  const [customer, setCustomer] = useState<IUser>(loadedCustomer!);
  const history = useHistory();

  async function modifyCustomer(e: FormEvent) {
    e.preventDefault();
    try {
      await props.customerActions.modifyUser(customer);
      history.goBack();
    } catch (error) {
      alert(error);
    }
  }
  function addrePlusOne() {
    const addrs = customer.addresses;
    addrs.push({
      bellComment: '',
      city: '',
      name: '',
      street: '',
      number: 0,
      zip: 1000,
    });
    return addrs;
  }

  return (
    <>
      <Container>
        <h1>Vásárló szerkesztése</h1>
        <Form onSubmit={modifyCustomer}>
          <FormGroup>
            <legend>Email cím: {customer.email}</legend>
            <legend>
              Facebook-os regisztráció: {customer.facebookId ? 'Igen' : 'Nem'}
            </legend>
          </FormGroup>

          <FormGroup>
            <Label>Neve</Label>
            <Input
              type='text'
              value={customer?.name}
              onChange={(e) =>
                setCustomer({ ...customer, name: e.target.value })
              }
            />
          </FormGroup>

          <FormGroup>
            <Label>Hűségpontjai</Label>
            <Input
              type='number'
              value={customer?.loyaltyPoint}
              onChange={(e) =>
                setCustomer({
                  ...customer,
                  loyaltyPoint: parseInt(e.target.value),
                })
              }
            />
          </FormGroup>

          <FormGroup>
            <Label>Címei</Label>
            <CustomerAddressView
              addresses={customer.addresses}
              onChange={(addr) => setCustomer({ ...customer, addresses: addr })}
            />
            {!customer.addresses.length && 'Nincs megadva cím...'}
            <Button
              color='success'
              onClick={() =>
                setCustomer({ ...customer, addresses: addrePlusOne() })
              }
            >
              +
            </Button>
          </FormGroup>
          <FormGroup>
            <Label>Allergének</Label>
            <ul>
              {customer.allergies.map((a) => (
                <li>{a.name} </li>
              ))}
            </ul>
          </FormGroup>

          <FormGroup>
            <Label>Telefonszáma</Label>
            <Input
              type='text'
              value={customer?.phone}
              onChange={(e) =>
                setCustomer({ ...customer, phone: e.target.value })
              }
            />
          </FormGroup>

          <Button color='primary' type='submit'>
            Várásrló szerkesztése
          </Button>
        </Form>
      </Container>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    customers: state.userReducer.users,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    customerActions: UserActions(dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;
export default connector(ModifyCustomerScreen);
