import {
  CouponActionType,
  CouponDispatchType,
  ICoupon,
  ICouponProdOrCategory,
} from './CouponTypes';
import Axios from 'axios';
import { ICategory, IProduct } from '../products/ProductTypes';

export const CouponActions = (
  dispatch: (action: CouponDispatchType) => {}
): CouponActionType => {
  return {
    fetchCoupons: async () => {
      const coupons = await (await Axios.get('/admin/coupons')).data;
      dispatch({ type: 'COUPONS_DOWNLOADED', payloadCoupons: coupons });
    },
    fetchWeeks: async () => {
      const coupons = await (
        await Axios.get('/admin/coupons/couponWeeks')
      ).data;
      dispatch({ type: 'COUPONS_WEEK_DOWNLOADED', payloadCoupons: coupons });
    },
    modifyWeeks: (week1, week2): Promise<any> => {
      const w1 = week1.map((item: any) => item[1]);
      const w2 = week2.map((item: any) => item[1]);

      return Axios.put(`/admin/coupons//update/couponWeeks`, {
        w1,
        w2,
      });
    },
    addCoupon: (
      coupon: ICoupon,
      selectedProducts: IProduct[],
      selectedCategories: ICategory[]
    ): Promise<any> => {
      coupon.availableForProductsAndCategories = [
        selectedCategories.map((p) => {
          return { categoryId: p.id };
        }),
        selectedProducts.map((p) => {
          return { productId: p.id };
        }),
      ].flat() as ICouponProdOrCategory[];
      return Axios.post('/admin/coupons', coupon);
    },
    deleteCoupon: async (coupon: ICoupon) => {
      await Axios.delete(`/admin/coupons/${coupon.id}`);
      return dispatch({ type: 'COUPON_DELETED', payloadCoupon: coupon });
    },
    modifyCoupon: (
      coupon: ICoupon,
      selectedProducts: IProduct[],
      selectedCategories: ICategory[]
    ): Promise<any> => {
      coupon.availableForProductsAndCategories = [
        selectedCategories?.map((p) => {
          return { categoryId: p.id };
        }),
        selectedProducts?.map((p) => {
          return { productId: p?.id };
        }),
      ].flat() as ICouponProdOrCategory[];
      return Axios.put(`/admin/coupons/${coupon.id}`, coupon);
    },
  };
};
