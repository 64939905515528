import React from 'react';
import { Button, Card, CardText } from 'reactstrap';
import { Parser } from 'json2csv';
import { UserStat } from '../../redux/workers/WorkerTypes';

type Props = {
  users: UserStat[];
};
export const UserOrderStatisctics = (props: Props) => {
  const stat = props.users;

  function createCSV(data: any[]): string | null {
    const fields = ['id', 'email', 'name', 'orders', 'finalPrice'];
    const opts = { fields, delimiter: ';' };

    try {
      const parser = new Parser(opts);
      const csv = parser.parse(data);

      return csv;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  async function createExport() {
    const csv = createCSV(stat);

    if (csv) {
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const date =
        now.toISOString().split('T')[0] +
        `-${hours < 10 ? '0' + hours : hours}-${
          minutes < 10 ? '0' + minutes : minutes
        }`;
      saveAs(blob, `user-statistics-export-${date}.csv`);
    }
  }
  return (
    <>
      <h5> TOP 20 legtöbbet rendelő felhasználó</h5>
      <Button onClick={(e) => createExport()}>Export</Button>
      {stat.map((u, i) => (
        <Card style={{ padding: '0.5rem' }}>
          <CardText>
            <strong>{i + 1}. </strong>
            <span>{u.name ?? 'Nincs név megadva...'} </span>
            <span>(ID: {u.id})</span>
          </CardText>
          <CardText>
            <b>Összes rendelés amit leadott:</b> {u.orders} db
          </CardText>
          <CardText>
            <b>Elköltött összeg:</b> {u.finalPrice} Ft
          </CardText>
        </Card>
      ))}
    </>
  );
};
