import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FormEvent, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from 'reactstrap';
import { RootState } from '../../redux/store';
import { WorkerActions } from '../../redux/workers/WorkerActions';
import { IUser } from '../../redux/workers/WorkerTypes';

const userTypes = [
  { label: 'Admin', value: 'ADMIN' },
  { label: 'Dolgozó', value: 'WORKER' },
  //{ label: "Felhasználó", value: "USER" }
];
const getInitialWorker = (): IUser => {
  return {
    name: '',
    email: '',
    password: '',
    type: 'WORKER',
    addresses: [],
    allergies: [],
    preparedOrders: [],
    orderedOrders: [],
    loyaltyPoint: 0,
  };
};

const NewWorkerScreen = (props: Props) => {
  let { id } = useParams();
  let loadedWorker: IUser | undefined;
  if (id) {
    loadedWorker = props.workers.find((p) => p.id === parseInt(id!));
  }
  const [worker, setWorker] = useState<IUser>(loadedWorker ?? getInitialWorker);
  const history = useHistory();
  const defvalue = worker.type === 'ADMIN' ? userTypes[0] : userTypes[1];

  async function addWorker(e: FormEvent) {
    e.preventDefault();
    if (id) {
      await props.workerActions.modifyUser(worker);
      window.location.reload();
    } else {
      await props.workerActions.addNewUser(worker);
    }
  }

  return (
    <Container>
      <Form onSubmit={addWorker}>
        <h1>{!id ? 'Új dolgozó hozzáadása' : 'Dolgozó szerkesztése'}</h1>
        <h3>Belépéshez szükséges adatok</h3>
        <Row form>
          <Col>
            <FormGroup>
              <Label>Email cím</Label>
              <Input
                type='email'
                value={worker?.email}
                required
                onChange={(e) =>
                  setWorker({ ...worker, email: e.target.value })
                }
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Jelszó {id ? 'módosítása' : 'létrehozása'}</Label>
              <Input
                type='password'
                minLength={6}
                required={id ? false : true}
                placeholder={id ? '**************' : ''}
                onChange={(e) =>
                  setWorker({ ...worker, password: e.target.value })
                }
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Jogosultság</Label>
              <Select
                defaultValue={defvalue}
                getOptionValue={(i) => i.value!.toString()}
                getOptionLabel={(i) => i.label}
                onChange={(value: any) =>
                  setWorker({ ...worker, type: value.value })
                }
                options={userTypes}
              />
            </FormGroup>
          </Col>
        </Row>
        <h3>Egyéb adatok</h3>
        <Row form>
          <Col>
            <FormGroup>
              <Label>Teljes név</Label>
              <Input
                type='text'
                required
                value={worker?.name}
                onChange={(e) => setWorker({ ...worker, name: e.target.value })}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Leírás</Label>
              <Input
                type='text'
                value={worker?.description}
                onChange={(e) =>
                  setWorker({ ...worker, description: e.target.value })
                }
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Telefonszám</Label>
              <Input
                type='text'
                required
                value={worker?.phone}
                onChange={(e) =>
                  setWorker({ ...worker, phone: e.target.value })
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Button color='primary' type='submit'>
          {!id ? 'Dolgozó létrehozása' : 'Dolgozó szerkesztése'}
          <span>
            <FontAwesomeIcon size='2x' icon={faUserCheck} />
          </span>
        </Button>
      </Form>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    workers: state.userReducer.workers,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    workerActions: WorkerActions(dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;
export default connector(NewWorkerScreen);
