import React from 'react';
import { Card, CardText } from 'reactstrap';
import { IOrder } from '../../redux/orders/OrderTypes';
import { ICategory } from '../../redux/products/ProductTypes';
import { CategoryStat } from '../../redux/workers/WorkerTypes';

type Props = {
  categories: CategoryStat[];
};

export const CategoryStaticstic = (props: Props) => {

  return (
    <>
      <h5>TOP 10 legtöbbet rendelt kategória</h5>
      {props.categories ? (
        props.categories.map((u, i) =>
          u ? (
            <Card style={{ padding: '0.5rem' }}>
              <CardText>
                <strong>{i + 1}. </strong>
                {u.categoryName ?? 'Nincs név megadva...'}
              </CardText>
              <CardText>
                <b>Összes rendelés ebből a kategóriából:</b>{' '}
                {u.occurance} db
              </CardText>
            </Card>
          ) : (
              <></>
            )
        )
      ) : (
          <></>
        )}
    </>
  );
};
