export const configFile = {
  serverBaseUrl:
    process.env.REACT_APP_API_URL_CMS_BASE_URL_IMG ||
    //  'http://localhost:3000/v1/cms',
    'https://api.pizzasprint.hu/v1/cms',
  // should uncomment the above line in prod
  //  serverBaseUrl:
  //   process.env.REACT_APP_API_URL_CMS_BASE_URL_IMG ||
  //    "https://api.pizzasprint.devz.hu/v1/cms",
};
export const BASE_SOCKET_IO_URL =
  process.env.REACT_APP_API_URL_SOCKETIO_BASE_URL || // 'http://localhost:4000';
  'https://api.pizzasprint.hu';
