import axios from 'axios'


export const configureAxios = (configFile: any, dispatch: any) => {
    console.log("AXIOS CONFIGURED");
    axios.defaults.baseURL = configFile.serverBaseUrl
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        axios.interceptors.request.use(request => {
            console.log('Starting Request', request)
            return request
        })
        axios.interceptors.response.use(response => {
            console.log('Response:', response)
            return response
        })
    }

    axios.interceptors.request.use(
        config => {
            const tkn = sessionStorage.getItem("token")
            if (tkn) {
                config.headers.authorization = "Bearer " + sessionStorage.getItem("token")
            }
            return config;
        },
        error => {
            Promise.reject(error)
        }
    );

    axios.interceptors.response.use(
        resp => {
            return resp
        },
        error => {
            const originalRequest = error.config;
            if (originalRequest.url === '/auth/refreshToken' && error.response.status === 401) {
                dispatch({type: "LOGIN_FAILURE"})
                sessionStorage.clear();
                localStorage.clear();
                return Promise.reject(error);
            }
            if (error.response?.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                console.log("Trying to refresh the token")
                return axios.post('/auth/refreshToken', {token: sessionStorage.getItem("refreshToken")})
                    .then(resp => {
                        console.log("Token refresh was successful! :)")
                        sessionStorage.setItem('token', resp.data.token);
                        localStorage.setItem('token', resp.data.token);
                        axios.defaults.headers.authorization = "Bearer " + resp.data.token;
                        return axios(originalRequest);
                    })
            }
 //           alert("HIBAA " + error)
            return Promise.reject(error);
        })
}