import React from 'react'
import { connect } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader, CardText, Col, Row } from 'reactstrap'
import { RootState } from '../../redux/store'
import { WorkerActions } from '../../redux/workers/WorkerActions'
import { IUser, WorkerActionType } from '../../redux/workers/WorkerTypes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUserEdit, faUserMinus } from "@fortawesome/free-solid-svg-icons";


type Props = {
    isOwnProfile: boolean
    worker: IUser
    workerActions?: WorkerActionType
}
const WorkerItemView = (props: Props) => {
    const { worker } = props
    const history = useHistory()
    const { path } = useRouteMatch();

    function onDeleteWorkerClicked() {
        props.workerActions?.deleteUserById(worker)
        history.push(`${path}/`);
        alert("Munkás kitörölve!")
    }

    function updateProductClicked() {
        history.push(`${path}/updateworker/${props.worker.id}`);
    }

    return (
        <Card style={{ background: props.isOwnProfile ? "#91edd1" : "", padding: '0.5rem' }}>
            <Row >
                <Col lg="8">
                    <CardHeader>
                        {props.isOwnProfile && <legend> <b><u>Saját profil</u></b></legend>}
                        <p><b>Id:</b> {worker.id}</p>

                        <Row>
                            <Col sm={2}><FontAwesomeIcon size="3x" icon={faUser} /></Col>
                            <Col><h5>Név: {worker.name ?? "-"}</h5>
                            </Col>
                        </Row>

                    </CardHeader>
                    <CardBody>
                        <CardText><b>Email cím:</b> {worker.email ?? "-"}</CardText>
                        <CardText><b>Telefonszám:</b> {worker.phone ?? "-"}</CardText>
                        <CardText><b>Leírás:</b> {worker.description ?? "-"}</CardText>
                    </CardBody>
                </Col>
                <Col>
                    <Button color="info" onClick={updateProductClicked}>{props.isOwnProfile ? "Saját profil szerkesztése " : "Szerkesztés "}<span><FontAwesomeIcon size="2x"
                        icon={faUserEdit} /></span></Button>
                    {!props.isOwnProfile && <Button color="danger" onClick={onDeleteWorkerClicked}>Törlés <span><FontAwesomeIcon size="2x"
                        icon={faUserMinus} /></span></Button>}

                    <h5>({worker.type === "ADMIN" ? "Admin" : "Dolgozó"})</h5>
                </Col>
            </Row>
        </Card >
    )
}

const mapStateToProps = (state: RootState) => {
    return {}
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        workerActions: WorkerActions(dispatch)
    }
}
const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(WorkerItemView)
