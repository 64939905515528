import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import AuthReducer from './auth/AuthReducer';
import CouponReducer from './coupons/CouponReducer';
import NewsReducer from './news/NewsReducer';
import OrderReducer from './orders/OrderReducer';
import ProductReducer from './products/ProductReducer';
import UserReducer from './workers/WorkerReducer';


const logger = (store: any) => (next: any) => (action: any) => {
    console.log('dispatching', action)
    let result = next(action)
    console.log('next state', store.getState())
    return result
}

const appReducer = combineReducers({
    authReducer: AuthReducer,
    orderReducer: OrderReducer,
    productReducer: ProductReducer,
    couponReducer: CouponReducer,
    userReducer: UserReducer,
    newsReducer: NewsReducer
});

export const INTERVAL_STACK: number[] = []
const rootReducer = (state: any, action: any) => {
    if (action.type === 'LOGOUT') {
        state = undefined
    }
    return appReducer(state, action)
}
//FOR MAKE CHANGES IN LOCAL
// const persistConfig = {
//     key: 'root',
//     storage,
// }
//const persistedReducer = persistReducer(persistConfig, rootReducer)


//@ts-ignore FOR REDUX DEV TOOLS
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
    //const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(logger)));
    let store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger)));
    //const persistor = persistStore(store)
    //return {store, persistor}
    return {store}
}

export type RootState = ReturnType<typeof rootReducer>
