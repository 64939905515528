import hu from 'date-fns/locale/hu';
import moment from 'moment';
import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { connect, ConnectedProps } from 'react-redux';
import { Col, Input, Row, Button, CustomInput } from 'reactstrap';
import OrderItemView from '../components/orders/OrderItemView';
import { OrderActions } from '../redux/orders/OrderActions';
import { IOrder } from '../redux/orders/OrderTypes';
import { RootState } from '../redux/store';
import { WorkerActions } from '../redux/workers/WorkerActions';

registerLocale('hu', hu);

const initDate = {
  from: new Date(),
  to: new Date(),
};
const ArchiveScreen = (props: Props) => {
  const [date, setDate] = useState(initDate);
  const [search, setSearch] = useState<string>('');
  const [isChecked, setIsChecked] = useState(false);

  /* Causes very poor performance because it fetches every archived order over and over, added an onClick search effect instead of useeffect see at line 31
    useEffect(() => {
        props.userActions.fetchOnlyWorkers()
        props.orderActions.fetchArchives(date.from, date.to)
    }, [date.from, date.to, props.userActions, props.orderActions])
 */

  //
  const handleSearch = () => {
    props.userActions.fetchOnlyWorkers();
    props.orderActions.fetchArchives(date.from, date.to);
  };

  //* ExtraSuperMagic function by Martin (just check all the values in an order to make it searchable)
  const myFilter = (order: IOrder) => {
    return (
      Object.values(order).some((value) =>
        value?.toString().toLowerCase().includes(search)
      ) ||
      Object.values(order.city).some((value) =>
        value?.toString().toLowerCase().includes(search)
      ) ||
      Object.values(order.preparedByWorker ?? {}).some((value) =>
        value?.toString().toLowerCase().includes(search)
      ) ||
      order.orderItems.some((i) => {
        return (
          Object.values(i).some((value) =>
            value?.toString().toLowerCase().includes(search)
          ) ||
          Object.values(i.item!).some((value) =>
            value?.toString().toLowerCase().includes(search)
          ) ||
          i.itemContent.some((ic) => {
            return (
              Object.values(ic).some((value) =>
                value?.toString().toLowerCase().includes(search)
              ) ||
              Object.values(ic.item!).some((value) =>
                value?.toString().toLowerCase().includes(search)
              )
            );
          })
        );
      })
    );
  };

  const pending = props.archiveOrders
    ?.filter(myFilter)
    ?.filter((o) => o.paymentStatus !== 'DONE');

  return (
    <>
      <h1>Archívum</h1>
      <legend>Szűkítés</legend>
      <Col lg={4} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        Tartalmazott szó:{' '}
        <Input
          type='text'
          placeholder='Keresés...'
          value={search}
          onChange={(e) => setSearch(e.target.value?.toLowerCase() ?? '')}
        />
        Dátum:
        <div>
          <DatePicker
            locale='hu'
            selected={date.from}
            onChange={(e) => setDate({ ...date, from: e! })}
            maxDate={new Date()}
            dateFormat='yyyy. MMMM d.'
          />
          <DatePicker
            locale='hu'
            selected={date.to}
            onChange={(e) => setDate({ ...date, to: e! })}
            maxDate={new Date()}
            dateFormat='yyyy. MMMM d.'
          />
        </div>
        <Button onClick={handleSearch}>Keresés</Button>
      </Col>

      <h3>Fizetésre vár / Sikertelen</h3>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CustomInput
          checked={isChecked}
          onChange={(e) => setIsChecked(!isChecked)}
          type='switch'
          id='isModifiable'
          label='Megjelenítés'
        />
      </div>

      {isChecked ? (
        <Row>
          {pending.length === 0 ? (
            <h4>Nincs várakozó fizetés</h4>
          ) : (
            pending.map((order) => (
              <Col lg='3' key={order.id}>
                <OrderItemView archive key={order.id} order={order} />
              </Col>
            ))
          )}
        </Row>
      ) : null}
      <h3>Teljesített</h3>
      <Row>
        {props.archiveOrders
          ?.filter(myFilter)
          ?.filter((o) => o.paymentStatus === 'DONE')
          .map((order) => (
            <Col lg='3' key={order.id}>
              <OrderItemView archive key={order.id} order={order} />
            </Col>
          ))}
      </Row>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    archiveOrders: state.orderReducer.archiveOrders,
    workers: state.userReducer.workers,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    orderActions: OrderActions(dispatch),
    userActions: WorkerActions(dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;
export default connector(ArchiveScreen);
